import React from 'react';
import { Container, Box, Skeleton } from '@material-ui/core';

const TestResultsSkelet = () => (
    <Box>
        <Skeleton variant="text" style={{ height: '400px', transform: 'scale(1, 0.97)' }} />
    </Box>
);

export default TestResultsSkelet;
