import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Button,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TestList from '../../components/tests/TestList';
import TestListSkelet from '../../skeletons/TestListSkelet';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import { useConfirm } from '../../components/confirm';

const Tests = () => {
    const [dataLoading, setDataLoading] = useState(true);
    const [tests, setTests] = useState([]);
    const confirm = useConfirm();

    const loadTests = () => {
        setDataLoading(true);

        RequestService.get('api/test', {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                setTests(resp.data.tests);
            }
            setDataLoading(false);
        });
    };

    const onDelete = (id) => {
        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the test?',
            onConfirm: () => {
                RequestService.delete(`api/test/${id}`, {
                    Authorization: `Bearer ${TokenStorage.getAccessToken()}`
                })
                .then((resp) => {
                    if (resp.status === 'success') {
                        loadTests();
                    }
                });
            }
        });
    };

    useEffect(() => {
        loadTests();
    }, []);

    return (
        <>
            <Helmet>
                <title>Tests</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        dataLoading ?
                            <TestListSkelet />
                            :
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box sx={{ marginLeft: 2 }}>
                                        <Link to="/app/tests/add">
                                            <Button color="primary" variant="contained">
                                                Add test
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{ pt: 3 }}>
                                    {
                                        tests.length > 0 ?
                                            <TestList testsData={tests} testDelete={onDelete} />
                                            :
                                            <Typography>Tests were not loaded</Typography>
                                    }
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default Tests;
