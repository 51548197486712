import {
    Box,
    Button,
    Card,
    Table,
    TableBody,
    TableCell, TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const PaymentListResult = ({ paymentData, handleChangePage, handleChangeLimit, page, limit, count, ...rest }) => {

    return (
        <Card {...rest}>
            <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Payment ID
                                </TableCell>
                                <TableCell>
                                    User ID
                                </TableCell>
                                <TableCell>
                                    User email
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paymentData.map((paymentData) => (
                                <TableRow hover key={paymentData._id}>
                                    <TableCell>
                                        {paymentData._id}
                                    </TableCell>
                                    <TableCell>
                                        {paymentData?.user !== null && paymentData.user ? paymentData.user?._id : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {paymentData?.user !== null && paymentData.user ? paymentData.user?.email : '-'}
                                    </TableCell>
                                    <TableCell sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <RouterLink to={`/app/payment-fullInfo/${paymentData._id}`}>
                                            <Button color="primary" variant="contained">
                                                Full info
                                            </Button>
                                        </RouterLink>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                    colSpan={7}
                                    count={count}
                                    rowsPerPage={limit}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeLimit}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Box>
            </PerfectScrollbar>
        </Card>
    );
};

PaymentListResult.propTypes = {
    paymentData: PropTypes.array.isRequired
};

export default PaymentListResult;
