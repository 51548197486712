import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { apiUrl } from '../../enviroment';
import { useConfirm } from '../../components/confirm';

const MainPageList = () => {

    const getU = useGet();
    const deleteU = useDelete();
    const confirm = useConfirm();

    const [isDataLoading, setIsDataLoading] = useState(true);

    const [data, setData] = useState([]);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 1400);
    };

    const loadData = () => {
        setIsDataLoading(true);

        let endpoint = `api/pages/main`;

        getU(endpoint)
            .then((resp) => {
                if (resp.statuss === 'success') {
                    setData(resp.data.pages);
                }
                setIsDataLoading(false);
            })
            .catch(() => {
                setData([]);
                setIsDataLoading(false);
            });
    };

    useEffect(() => {
        loadData();
    }, []);

    const onDelete = (id) => {

        confirm({
            title: 'Deleting main page',
            content: 'Are you sure you want to delete the main page?',
            onConfirm: () => {
                deleteU(`api/pages/main/${id}`)
                    .then((resp) => {
                        if (resp.statuss === 'success') {
                            loadData();
                        }
                    })
                    .catch((e)=>{
                        console.log( e.response?.data?.message)
                        showAlert('error', e.response?.data?.message);
                    });
            }
        });
    };


    return (
        <>
            <Helmet>
                <title>Main page</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type}
                           style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                        {alert.txt}
                    </Alert>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box sx={{ marginLeft: 2 }}>
                                        <Link to="/app/main-page/add">
                                            <Button color="primary" variant="contained">
                                                Add main page
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{ pt: 3 }}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{ minWidth: 1050 }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ width: 80 }}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Title
                                                            </TableCell>
                                                            <TableCell>
                                                                Text
                                                            </TableCell>
                                                            <TableCell >
                                                                Language
                                                            </TableCell>
                                                            <TableCell >

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data?.map((item) => (
                                                            <TableRow hover key={item.id}>
                                                                <TableCell sx={{ width: 80 }}>
                                                                    {item?._id}
                                                                </TableCell>
                                                                <TableCell >
                                                                    {item?.title}
                                                                </TableCell>
                                                                <TableCell >
                                                                    {item?.text}
                                                                </TableCell>
                                                                <TableCell >
                                                                    {item?.language?.title}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{ display: 'flex' }}>
                                                                        <Box sx={{ ml: 2 }}>
                                                                            <Link to={`/app/main-page/edit/${item?._id}`}>
                                                                                <Button color="primary"
                                                                                        variant="contained">
                                                                                    Edit
                                                                                </Button>
                                                                            </Link>
                                                                        </Box>
                                                                        <Box>
                                                                            <Button
                                                                                sx={{ ml: 1 }}
                                                                                color="error"
                                                                                variant="contained"
                                                                                onClick={() => onDelete(item?._id)}>
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default MainPageList;
