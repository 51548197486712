import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import MoneyIcon from '@material-ui/icons/Money';
import { green, red } from '@material-ui/core/colors';

const ProfitPerMonth = ({ profitPerMonth, ...props }) => {


    const data = profitPerMonth === undefined ? 0 : profitPerMonth;


    let lastValue = data[Object.keys(profitPerMonth)[Object.keys(profitPerMonth).length - 1]];


    const culc = () => {
        if (data[Object.keys(data)[Object.keys(data).length - 2]] && data[Object.keys(data)[Object.keys(data).length - 2]] > 0) {
            return lastValue - data[Object.keys(data)[Object.keys(data).length - 2]];
        } else {
            return lastValue;
        }
    };


    return (
        <Card
            sx={{ height: '100%' }}
            {...props}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            PROFIT PER MONTH
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            $
                            {profitPerMonth !== undefined
                                ? lastValue / 100
                                : <span>data no loaded</span>
                            }

                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: red[600],
                                height: 56,
                                width: 56
                            }}
                        >
                            <MoneyIcon/>
                        </Avatar>
                    </Grid>
                </Grid>
                {
                    culc() < 0
                        ? (
                            <Box
                                sx={{
                                    pt: 2,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <ArrowDownwardIcon sx={{ color: red[900] }}/>
                                <Typography
                                    sx={{
                                        color: red[900],
                                        mr: 1
                                    }}
                                    variant="body2"
                                >
                                    $
                                    {
                                        culc() / 100
                                    }
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Since last month
                                </Typography>
                            </Box>
                        )
                        : (
                            <Box
                                sx={{
                                    pt: 2,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <ArrowUpward sx={{ color: green[900] }}/>
                                <Typography
                                    sx={{
                                        color: green[900],
                                        mr: 1
                                    }}
                                    variant="body2"
                                >
                                    $
                                    {
                                        culc() / 100
                                    }
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Since last month
                                </Typography>
                            </Box>
                        )
                }

            </CardContent>
        </Card>
    );
};

export default ProfitPerMonth;
