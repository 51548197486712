import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    Input
} from '@material-ui/core';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import getUserName from '../../API/getUserName';
import { apiUrl } from '../../enviroment';
import { useDispatch, useSelector } from 'react-redux';
import { get, put, useGet, usePut } from '../../API/request';
import { setAva } from '../../redux/slices/dashboard';

const AccountProfile = (props) => {
    const [uploadedAvatar, setUploadedAvatar] = useState('');
    const [userAvatar, setUserAvatar] = useState('');
    const [userName, setUserName] = useState('');
    const dispatch = useDispatch();
    const getU = useGet();
    const putU = usePut();

    const uploadNewAvatar = () => {
        if (uploadedAvatar !== '') {
            const { id } = TokenStorage.getUser();

            let data = new FormData();
            data.append('avatar', uploadedAvatar);

            putU(`api/users/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        setUserAvatar(`${apiUrl}/uploads/avatars/${resp.data.user.avatar}`);
                        dispatch(setAva(`${apiUrl}/uploads/avatars/${resp.data.user.avatar}`));
                    }
                });
        }
    };

    const avaUploaded = (event) => {
        setUploadedAvatar(event.target.files[0]);
    };


    useEffect(async () => {

        await getU(`api/users/current`)
            .then((response) => {
                if (response.status === 'success') {
                    setUserName(response.data.user.name);
                    setUserAvatar(`${apiUrl}/uploads/avatars/${response.data.user.avatar}`);
                } else {
                    setUserName('Admin');
                }
            }).catch((e) => {
                setUserName('Admin');
            });

    }, []);

    useEffect(() => {
        uploadNewAvatar();
    }, [uploadedAvatar]);

    return (
        <Card {...props}>
            <CardContent>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Avatar
                        src={userAvatar}
                        sx={{
                            height: 100,
                            width: 100
                        }}
                    />
                    <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h3"
                    >
                        {userName}
                    </Typography>
                </Box>
            </CardContent>
            <Divider/>
            <CardActions>
                <Box style={{ width: '100%' }}>
                    <Input accept="xlsx/*" type="file" style={{ display: 'none' }} id="userAvatarUpload"
                           onChange={avaUploaded}/>
                    <label htmlFor="userAvatarUpload">
                        <Button variant="text" style={{ marginTop: '7px' }} fullWidth component="span">
                            Upload picture
                        </Button>
                    </label>
                </Box>
            </CardActions>
        </Card>
    );
};

export default AccountProfile;
