import React, { useEffect, useState } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import { useGet } from '../../API/request';
import { useParams } from 'react-router-dom';


const PieDiag = () => {

    const { id } = useParams();
    const getU = useGet();
    const [colors, setColors] = useState([]);

    useEffect(() => {
        getU(`api/tests/proficiency/results/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    console.log(resp.data);
                    setColors(resp.data.order);
                }
            })
            .catch(() => {

            })
            .finally(() => {

            });
    }, []);



    const pieChartData = (canvasElem) => {
        const ctx = canvasElem.getContext('2d');
        const grad = ctx.createLinearGradient(0, 100, 600, 100);
        grad.addColorStop(0, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.03, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.04, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.09, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.14, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.17, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.19, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.22, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.26, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.28, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.31, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.33, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.35, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.37, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.4, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.42, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.45, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.47, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.5, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.53, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.56, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.58, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.6, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.63, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.65, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.69, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.72, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.74, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.76, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.79, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.82, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.85, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.87, 'rgba(238, 130, 238, 1)');
        grad.addColorStop(0.89, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.93, 'rgba(0, 188, 212, 1)');
        grad.addColorStop(0.96, 'rgba(255, 255, 0, 1)');
        grad.addColorStop(0.99, 'rgba(238, 130, 238, 1)');

        // gradient.addColorStop(0.5, 'rgba(238, 130, 238, 1)');
        // gradient.addColorStop(1, 'rgba(0, 209, 255, 1)');

        let filter = colors.filter((item) => item.order !== 13);
        let sort = filter.sort((x, y) => x.order - y.order);
        let map = sort.map((item) => item.colorCode === "кристалл" ? grad : item.colorCode);
        let concat = map.concat(map.splice(0, map.length - 6));
console.log(concat)
        return {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            datasets: [{
                data: ['1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1'],
                backgroundColor: concat
            }],
            text: '23%',
        }
    };

    const plugins = {
        beforeDraw: function(chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 155);
            ctx.font = fontSize + 'em sans-serif';
            ctx.textBaseline = 'top';
            var text = 13,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2.2;
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    };


    const options = {
        plugins: {
            legend: {
                display: false
            },
        },
    };


    return (
        <Doughnut
            type="pie"
            width={130}
            height={50}
            options={options}
            data={pieChartData}
            plugins={[plugins]}
        />
    );
};
export default PieDiag;
