import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import UserFormEdit from '../../components/users/UserFormEdit';
import UserSetPassForm from '../../components/users/UserSetPassForm';
import { useGet, usePost, usePut } from '../../API/request';
import UserListSkelet from '../../skeletons/UserListSkelet';

const LanguagesAdd = () => {

    const navigate = useNavigate();

    const postU = usePost();

    const [values, setValues] = useState({
        name: '',
        title: '',
        isHiden: 'true'
    });
    const [errors, setErrors] = useState({
        name: false,
        title: false,
        isHiden: false
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });


    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.name === '') {
            validComplete = false;
            formErrors.name = true;
        }

        if (values.title === '') {
            validComplete = false;
            formErrors.title = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            postU(`api/languages`, {
                name: values.name,
                title: values.title,
                isHiden: values.isHiden
            })
                .then((resp) => {
                    if (resp.statuss === 'success') {
                        showAlert('success', 'Language edited');
                    } else {
                        showAlert('error', 'Error occured');
                    }
                })
                .catch((err) => {
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() => {
                });
        }
    };


    return (
        <>
            <Helmet>
                <title>Add language</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Add language"
                                />
                                <Divider/>
                                <CardContent sx={{ position: 'relative' }}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Title"
                                        margin="normal"
                                        name="title"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.title}
                                        variant="outlined"
                                        error={errors.title}
                                    />
                                    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                        <InputLabel id="IsHiden">IsHiden</InputLabel>
                                        <Select
                                            labelId="IsHiden"
                                            name="IsHiden"
                                            value={values.isHiden}
                                            defaultValue={true}
                                            label="IsHiden"
                                            onChange={(evt) => {
                                                setValues({
                                                    ...values,
                                                    isHiden: evt.target.value
                                                });
                                                setNothingChanged(false);
                                            }}
                                        >
                                            <MenuItem value="true">True</MenuItem>
                                            <MenuItem value="false">False</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Alert severity={alert.type}
                                           style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>

                                <Divider/>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            </Card>
                        </form>

                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default LanguagesAdd;
