import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../../API/request';
import { apiUrl } from '../../../enviroment';
import { useConfirm } from '../../../components/confirm';
import Typography from '@material-ui/core/Typography';

const OrderEmailList = () => {

    const getU = useGet();

    const [data, setData] = useState('');

    const [isDataLoading, setIsDataLoading] = useState(true);

    const loadData = () => {
        setIsDataLoading(true);

        let endpoint = `api/consultations/email`;

        getU(endpoint)
            .then((resp) => {
                if (resp.statuss === 'success') {
                    setData(resp.data?.consultationEmail);
                }
                setIsDataLoading(false);
            })
            .catch(() => {
                setData('');
                setIsDataLoading(false);
            });
    };

    console.log(data);


    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Helmet>
                <title>Order Email</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet isWithTopButton={false}/>
                            :
                            <>
                                <Box sx={{ pt: 3 }}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{ minWidth: 400 }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Email
                                                            </TableCell>
                                                            <TableCell/>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow hover>
                                                            <TableCell>
                                                                {data?.email}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box sx={{ display: 'flex' }}>
                                                                    <Box sx={{ ml: 2 }}>
                                                                        <Link to={`/app/consultations/email/edit`}>
                                                                            <Button color="primary"
                                                                                    variant="contained">
                                                                                Edit
                                                                            </Button>
                                                                        </Link>
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                                <Card style={{
                                    marginTop: 20
                                }}>
                                    <Box style={{
                                        padding: 20
                                    }}>
                                        <Typography style={{
                                            color: '#b1b1b1',
                                            textAlign: 'center'
                                        }}>
                                            Here you can edit the email to which notifications will be sent after
                                            someone orders a full diagnosis or consultation
                                        </Typography>

                                    </Box>
                                </Card>
                            </>
                    }

                </Container>
            </Box>
        </>
    );
};

export default OrderEmailList;
