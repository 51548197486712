import { apiUrl } from '../enviroment';
import TokenStorage from './TokenStorage';

export default class RequestService {
    static encodePayload(payload) {
        let encoded = [];

        for (let key in payload) {
            encoded.push(`${key}=${encodeURIComponent(payload[key])}`);
        }

        return encoded.join('&');
    }

    static async refreshToken() {
        let resp = await fetch(`${apiUrl}/api/auth/refresh`, {
            headers: {
                'Authorization': `Bearer ${TokenStorage.getRefreshToken()}`
            },
            method: 'POST'
        });

        let json = await resp.json();

        if (json.status === 'success') {
            let tokens = json.tokens;
            TokenStorage.setAccessToken(tokens.access);
            TokenStorage.setRefreshToken(tokens.refresh);
            TokenStorage.setTokenExpire(tokens.expiresIn);
            TokenStorage.setTokenReceived(Date.now() / 1000);
        }
    }

    static isTokenFresh() {
        let now = Date.now() / 1000;
        let tokenExpires = parseInt(TokenStorage.getTokenExpire());

        return now - parseInt(TokenStorage.getTokenReceived()) < tokenExpires;
    }

    static async postRequest(endpoint, headers, payload, doEncode) {
        let encodedPayload;

        if (doEncode) {
            encodedPayload = this.encodePayload(payload);
        }

        let resp = await fetch(`${apiUrl}/${endpoint}`, {
            method: 'POST',
            headers: headers,
            body: doEncode ? encodedPayload : payload
        });

        let json = await resp.json();

        return json;
    }

    static async getRequest(endpoint, headers) {
        let resp = await fetch(`${apiUrl}/${endpoint}`, {
            method: 'GET',
            headers: headers
        });

        let json = await resp.json();

        return json;
    }

    static async deleteRequest(endpoint, headers) {
        let resp = await fetch(`${apiUrl}/${endpoint}`, {
            method: 'DELETE',
            headers: headers
        });

        let json = await resp.json();

        return json;
    }

    static async putRequest(endpoint, headers, payload, doEncode) {
        let encodedPayload;

        if (doEncode) {
            encodedPayload = this.encodePayload(payload);
        }

        let resp = await fetch(`${apiUrl}/${endpoint}`, {
            method: 'PUT',
            headers: headers,
            body: doEncode ? encodedPayload : payload
        });

        let jsonData = await resp.json();

        return jsonData;
    }

    static async post(endpoint, headers, payload, doEncode = true) {
        if (TokenStorage.getAccessToken() !== '' && !this.isTokenFresh()) {
            await this.refreshToken();

            if ('Authorization' in headers) {
                delete headers['Authorization'];
                headers['Authorization'] = `Bearer ${TokenStorage.getAccessToken()}`;
            }
        }

        return this.postRequest(endpoint, headers, payload, doEncode);
    }

    static async get(endpoint, headers) {
        if (TokenStorage.getAccessToken() !== '' && !this.isTokenFresh()) {
            await this.refreshToken();

            if ('Authorization' in headers) {
                delete headers['Authorization'];
                headers['Authorization'] = `Bearer ${TokenStorage.getAccessToken()}`;
            }
        } 

        return this.getRequest(endpoint, headers);
    }

    static async delete(endpoint, headers) {
        if (TokenStorage.getAccessToken() !== '' && !this.isTokenFresh()) {
            await this.refreshToken();

            if ('Authorization' in headers) {
                delete headers['Authorization'];
                headers['Authorization'] = `Bearer ${TokenStorage.getAccessToken()}`;
            }
        } 

        return this.deleteRequest(endpoint, headers);
    }

    static async put(endpoint, headers, payload, doEncode = true) {
        if (TokenStorage.getAccessToken() !== '' && !this.isTokenFresh()) {
            await this.refreshToken();

            if ('Authorization' in headers) {
                delete headers['Authorization'];
                headers['Authorization'] = `Bearer ${TokenStorage.getAccessToken()}`;
            }
        } 

        return this.putRequest(endpoint, headers, payload, doEncode);
    }
}
