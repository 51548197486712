import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../../API/request';
import { BallTriangle } from 'react-loader-spinner';

const PriceAdd = () => {

    const navigate = useNavigate();

    const postU = usePost();
    const getU = useGet();

    const [languages, setLanguages] = useState([]);

    const [values, setValues] = useState({
        price: '',
        language: ''
    });
    const [errors, setErrors] = useState({
        price: false,
        language: false
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);
    const [isShowLoader, setIsShowLoader] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });


    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.price.trim() === '') {
            validComplete = false;
            formErrors.price = true;
        }

        if (values.language.trim() === '') {
            validComplete = false;
            formErrors.language = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (validate()) {
            setSubmitDisabled(true);

            postU(`api/prices`, {
                language: values.language,
                priceId: values.price
            })
                .then((resp) => {
                    if (resp.statuss === 'success') {
                        showAlert('success', 'Price was added');
                    } else {
                        showAlert('error', 'Error occured');
                    }
                })
                .catch((err) => {
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() => {
                });
        }
    };

    const getData = () => {
        setIsShowLoader(true);

        getU(`api/languages`)
            .then((resp) => {
                if (resp.statuss === 'success') {
                    let languages = resp.data.languages;
                    setLanguages(languages);
                    setValues({
                        ...values,
                        language: languages[0]?._id
                    });
                }
            })
            .catch()
            .finally(() => {
                setIsShowLoader(false);
            });

    };
    useEffect(() => {
        getData();
    }, []);

    if (isShowLoader) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Add price</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Add price"
                                />
                                <Divider/>
                                <CardContent sx={{ position: 'relative' }}>
                                    <TextField
                                        fullWidth
                                        label="Price"
                                        margin="normal"
                                        name="price"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.price}
                                        variant="outlined"
                                        error={errors.price}
                                    />
                                    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                        <InputLabel id="language">Language</InputLabel>
                                        <Select
                                            labelId="language"
                                            name="language"
                                            value={values.language}
                                            label="Language"
                                            onChange={(evt) => {
                                                setValues({
                                                    ...values,
                                                    language: evt.target.value
                                                });
                                                setNothingChanged(false);
                                            }}
                                        >
                                            {
                                                languages.map((item) =>
                                                    <MenuItem value={item?._id}>{item?.title}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                    <Alert severity={alert.type}
                                           style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>

                                <Divider/>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            </Card>
                        </form>

                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default PriceAdd;
