import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import EmailTemplateListResults from '../../components/email-template/EmailTemplateListResults';
import EmailTemplatesSkelet from '../../skeletons/EmailTemplatesSkelet';
import { useConfirm } from '../../components/confirm';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import { useDelete, useGet } from '../../API/request';

const EmailTemplateList = () => {


    const [emailTemplates, setEmailTemplates] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();

    const loadData = async () => {
        setDataLoading(true);

        getU('api/templates/email')
        .then((resp) => {
            if (resp.status === 'success') {
                let templates = resp.data.templates;
                setEmailTemplates(templates);
            }
            setDataLoading(false);
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    const onDelete = (id) => {

        confirm({
            title: 'Deleting',
            content: 'Are you sure you want to delete the template?',
            onConfirm: () => {
                deleteU(`api/templates/email/${id}`)
                .then((resp) => {
                    console.log("opened")
                    if (resp.status === 'success') {
                        loadData();
                    }
                })
                    .catch((e)=>{
                        console.log("opened")
                        console.log(e.response)
                    });
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>Email template list</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        dataLoading ?
                            <EmailTemplatesSkelet />
                            :
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Link to="/app/email-templates/create">
                                        <Button color="primary" variant="contained">
                                            Add email template
                                        </Button>
                                    </Link>
                                </Box>
                                <Box sx={{ pt: 3 }}>
                                    {
                                        emailTemplates.length > 0 ?
                                            <EmailTemplateListResults templates={emailTemplates} onDelete={onDelete} />
                                            :
                                            <Typography color="textPrimary" variant="h2">
                                                Templates were not loaded
                                            </Typography>
                                    }
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default EmailTemplateList;
