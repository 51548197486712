/* eslint-disable */
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/styles';
import { Link, useParams, useNavigate } from 'react-router-dom';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    wrapAvatar: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex'
    },
    wrapInner: {
        flexDirection: 'row',
        display: 'flex',
    },
    label: {
        width: 200,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: 10,
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto'
    },
    panel: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#5664d21c'
    },
    panelInCareington: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#79b3ff1c',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    }
});

const TestInfo = () => {
    const { id } = useParams();
    const [testName, setTestName] = useState('');
    const [testId, setTestId] = useState('');
    const [testValues, setTestValues] = useState([]);
    const styles = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        RequestService.get(`api/test/${id}`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                let testData = resp.data.test;
                setTestName(testData.name);
                setTestId(testData.id);
                setTestValues(Object.values(testData.values));
            }
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Test info</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Card>
                        <CardContent sx={{ p: 3 }}>
                            <div className={styles.wrapAvatar}>
                                <div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Test id:
                                            </div>
                                            <div className={styles.text}>
                                                {testId}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Test name:
                                            </div>
                                            <div className={styles.text}>
                                                {testName}
                                            </div>
                                        </div>
                                        {
                                            testValues.map((item, ind) => {
                                                return <div className={styles.wrap} key={ind}>
                                                            <div className={styles.label}>
                                                                Question {ind + 1}
                                                            </div>
                                                            <div className={styles.text}>
                                                                {item.name}
                                                            </div>
                                                        </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                        <Divider />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                            <Link to={`/app/tests/edit/${id}`}>
                                <Button color="primary" variant="contained">
                                    Edit
                                </Button>
                            </Link>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default TestInfo;
