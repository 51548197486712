import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Button,
    Alert,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';

const TestEdit = () => {
    const [testName, setTestName] = useState('');
    const [testNameErr, setTestNameErr] = useState(false);
    const [testType, setTestType] = useState('');

    const [values, setValues] = useState(new Array(13).fill(''));
    const [errors, setErrors] = useState(new Array(13).fill(false));

    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const { id } = useParams();

    const numerals = [
        'First', 'Second', 'Third',
        'Fourth', 'Fifth', 'Sixth',
        'Seventh', 'Eighth', 'Ninth',
        'Tenth', 'Eleventh', 'Twelfth',
        'Thirteenth'
    ];

    const navigate = useNavigate();

    const handleNameChange = (event) => {
        setTestName(event.target.value);
        setTestNameErr(false);
    };

    const handleChange = (event, ind) => {
        let formValues = [...values];
        let formErrors = [...errors];
        formValues[ind] = event.target.value;
        formErrors[ind] = false;
        setValues(formValues);
        setErrors(formErrors);
    };

    const showAlert = (type, text) => {
        setAlert({
          txt: text,
          type,
          isVisible: true
        });

        setTimeout(() => {
          setAlert({
            txt: text,
            type,
            isVisible: false
          });

          setSubmitDisabled(false);
        }, 1400);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = [...errors];

        if (testName.trim() === '') {
            validComplete = false;
            setTestNameErr(true);
            showAlert('error', 'Type test name');
        }

        values.forEach((item, ind) => {
            if (item.trim() === '') {
                validComplete = false;
                formErrors[ind] = true;
                showAlert('error', 'Type all questions');
            }
        });

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        setSubmitDisabled(true);

        if (validate()) {
            let valuesObj = {};
            let payload = {
                name: testName,
                type: testType
            };

            values.forEach((item, ind) => {
                valuesObj[ind + 1] = {
                    name: item
                };
            });

            payload.values = valuesObj;
            setIsShowLoader(true);

            RequestService.put(`api/test/${id}`, {
                Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
                'Content-Type': 'application/json'
            }, JSON.stringify(payload), false)
            .then((resp) => {
                setIsShowLoader(false);
                console.log(resp);

                if (resp.status === 'success') {
                    showAlert('success', 'Test edited');
                } else {
                    showAlert('error', 'Error occured');
                }
            })
            .catch((err) => {
                setIsShowLoader(false);
                showAlert('error', 'Server error');
            });
        }
    };

    useEffect(() => {
        RequestService.get(`api/test/${id}`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                let test = resp.data.test;
                setTestName(test.name);

                let testVals = Object.values(test.values);

                let testValsBuf = [];

                testVals.forEach((item, ind) => {
                    testValsBuf.push(item.name);
                });

                setTestType(test.type);
                setValues(testValsBuf);
            }
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Edit test</title>
            </Helmet>
            <Box sx={{ pt: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <form>
                        <Card>
                            <CardHeader
                                title="Edit test"
                            />
                            <Divider />
                            <CardContent sx={{ position: 'relative' }}>
                                {isShowLoader && (
                                    <Box sx={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      backgroundColor: '#ffffff',
                                      zIndex: 5
                                    }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                )}
                                <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                    <InputLabel id="testType">Type</InputLabel>
                                    <Select
                                      labelId="testType"
                                      name="type"
                                      value={testType}
                                      label="Role"
                                      onChange={(evt) => setTestType(evt.target.value)}
                                    >
                                        <MenuItem value="adults">For adults</MenuItem>
                                        <MenuItem value="kids">For kids</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Test name"
                                    margin="normal"
                                    name="testName"
                                    onChange={handleNameChange}
                                    type="text"
                                    value={testName}
                                    variant="outlined"
                                    error={testNameErr}
                                />
                                {
                                    values.map((item, ind) => {
                                        return <TextField
                                                  key={ind}
                                                  fullWidth
                                                  label={`${numerals[ind]} question`}
                                                  margin="normal"
                                                  name={numerals[ind]}
                                                  onChange={(event) => handleChange(event, ind)}
                                                  type="text"
                                                  value={values[ind]}
                                                  variant="outlined"
                                                  error={errors[ind]}
                                                />
                                    })
                                }
                                <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                    {alert.txt}
                                </Alert>
                            </CardContent>
                            <Divider />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={submit}
                                    disabled={submitDisabled}
                                >
                                    Edit test
                                </Button>
                            </Box>
                        </Card>
                    </form>
                </Container>
            </Box>
        </>
    );
};

export default TestEdit;
