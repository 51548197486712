import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    Typography,
    Input,
    TextField,
    FormControl,
    InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import { useGet, usePut } from '../../../API/request';
import UserListSkelet from '../../../skeletons/UserListSkelet';
import { BallTriangle } from 'react-loader-spinner';

const OrderEmailEdit = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const getU = useGet();
    const putU = usePut();

    const [values, setValues] = useState({
        email: ''
    });
    const [errors, setErrors] = useState({
        email: false
    });

    const [statuses, setStatuses] = useState([]);

    const [isShowLoader, setIsShowLoader] = useState(false);

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.email.trim() === '') {
            validComplete = false;
            formErrors.email = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Nothing changed');
            return;
        }

        if (validate()) {
            setSubmitDisabled(true);

            putU(`api/consultations/email`, {
                email: values.email
            })
                .then((resp) => {
                    if (resp.statuss === 'success') {
                        showAlert('success', 'Email was edited');
                    } else {
                        showAlert('error', 'Error occured');
                    }
                })
                .catch((err) => {
                    showAlert('error', err?.response?.data?.message);
                })
                .finally(() => {

                });
        }
    };

    const getData =  () => {
        setIsShowLoader(true);
        getU(`api/consultations/email`)
            .then((resp) => {
                if (resp.statuss === 'success') {
                    let consultationEmail = resp.data.consultationEmail;
                    setValues({
                        email: consultationEmail?.email
                    })
                }
            })
            .catch()
            .finally(() => {
                setIsShowLoader(false);
            });
    };
    useEffect(() => {
        getData();
    }, []);

    if (isShowLoader){
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Edit email</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Edit email"
                                />
                                <Divider/>
                                <CardContent sx={{ position: 'relative' }}>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        margin="normal"
                                        name="email"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.email}
                                        variant="outlined"
                                        error={errors.email}
                                    />
                                    <Alert severity={alert.type}
                                           style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>

                                <Divider/>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </form>

                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default OrderEmailEdit;
