import { useEffect, useContext, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    List,
    Typography
} from '@material-ui/core';
import {
    BarChart as BarChartIcon,
    Users as UsersIcon,
    Mail as MailIcon,
    DollarSign as Payment,
    Settings,
    Type,
    Layout,
    Book,
    List as ListIcon, PhoneForwarded
} from 'react-feather';
import NavItem from './NavItem';
import TokenStorage from '../API/TokenStorage';
import { apiUrl } from '../enviroment';
import { useGet } from '../API/request';
import { useSelector } from 'react-redux';

const itemsDesign = [
    {
        href: '/app/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard'
    },
    {
        href: '/app/users',
        icon: UsersIcon,
        title: 'Users'
    },
    {
        href: '/app/admins',
        icon: UsersIcon,
        title: 'Admins'
    }
];

const itemsSystem = [
    {
        href: '/app/email-templates',
        icon: MailIcon,
        title: 'Email templates'
    },
    // {
    //     href: '/app/tests',
    //     icon: TestIcon,
    //     title: 'Tests'
    // },
    {
        href: '/app/test-results',
        icon: ListIcon,
        title: 'Test results'
    },
    {
        icon: Payment,
        title: 'Payment',
        list: [
            {
                href: '/app/payment',
                icon: Book,
                title: 'Payment'
            },
            {
                href: '/app/prices',
                icon: Book,
                title: 'Prices'
            },
        ]
    },
    {
        href: '/app/test-settings',
        icon: Settings,
        title: 'Test settings'
    },
    {
        href: '/app/main-page',
        icon: Layout,
        title: 'Main page'
    },
    {
        href: '/app/languages',
        icon: Type,
        title: 'Languages'
    },
    {
        icon: Book,
        title: 'Order',
        list: [
            {
                href: '/app/consultations',
                icon: Book,
                title: 'Consultations'
            },
            {
                href: '/app/diagnostics',
                icon: Book,
                title: 'Diagnostics'
            },
            {
                href: '/app/consultation/email',
                icon: Book,
                title: 'Email'
            },
        ]
    },
    {
        href: '/app/feedbacks',
        icon: PhoneForwarded,
        title: 'Feedbacks'
    }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
    const location = useLocation();
    const [userAva, setUserAva] = useState('');
    const [userName, setUserName] = useState('Admin');
    const ava = useSelector(state => state.dashboard.ava)
    const getU = useGet();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    useEffect(() => {
        if(TokenStorage.getUser()){
            const { id } = TokenStorage.getUser();
            getU(`api/users/${id}`)
                .then((resp) => {
                    if (resp.status === 'success') {
                        let user = resp.data.user;
                        setUserAva(`${apiUrl}/uploads/avatars/${resp.data.user.avatar}`);
                        setUserName(user.name);

                    }
                })
                .catch((err) => {

                });
        }

    }, [ava]);

    useEffect(()=>{

    },[])

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2
                }}
            >
                <Avatar
                    component={RouterLink}
                    src={userAva}
                    sx={{
                        cursor: 'pointer',
                        width: 64,
                        height: 64
                    }}
                    to="/app/account"
                />
                <Typography
                    color="textPrimary"
                    variant="h5"
                >
                    {userName}
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{ px: 2 }}>
                <List>
                    {itemsDesign.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                </List>
            </Box>
            <Divider/>
            <Box sx={{ px: 2 }}>
                <List>
                    {itemsSystem.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            list={item?.list}
                        />
                    ))}
                </List>
            </Box>
            <Box sx={{ flexGrow: 1 }}/>
        </Box>
    );

    return (
        <>
            <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    PaperProps={{
                        sx: {
                            width: 256
                        }
                    }}
                >
                    {content}
                </Drawer>
            </Box>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Drawer
                    anchor="left"
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: 256,
                            top: 64,
                            height: 'calc(100% - 64px)'
                        }
                    }}
                >
                    {content}
                </Drawer>
            </Box>
        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => {
    },
    openMobile: false
};

export default DashboardSidebar;
