import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import CustomersPerMonth from '../components/dashboard/CustomersPerMonth';
import LatestOrders from '../components/dashboard/LatestOrders';
import LatestProducts from '../components/dashboard/LatestProducts';
import Sales from '../components/dashboard/Sales';
import ProfitPerMonth from '../components/dashboard/ProfitPerMonth';
import TotalCustomers from '../components/dashboard/TotalCustomers';
import TotalProfit from '../components/dashboard/TotalProfit';
import TrafficByDevice from '../components/dashboard/TrafficByDevice';
import React, { useEffect, useState } from 'react';
import { useGet } from '../API/request';
import { BallTriangle } from 'react-loader-spinner';
import PieDiag from '../components/PieDiag';
import '../styles/Diagram/Diagram.css';

const Dashboard = () => {

    const [loaded, setLoaded] = useState(true);

    const getU = useGet();

    const [data, setData] = useState({
        'customersPerMonth': {
            '1970-01': 0
        },
        'profitPerMonth': {
            '1970-01': 0
        },
        'totalCustomers': 0,
        'totalProfit': 0
    });

    useEffect(() => {
        getU('api/statistics/general')
            .then((response) => {
                if (response.status === 'success') {
                    setData(response.data);
                    setLoaded(false);
                }
            })
            .catch(() => {
                setLoaded(false);
            });
    }, []);

    if (loaded){
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={3}
                            xs={12}
                        >
                            <CustomersPerMonth customersPerMonth={data?.customersPerMonth}/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={3}
                            xs={12}
                        >
                            <TotalCustomers sx={{ height: '100%' }} totalCustomers={data?.totalCustomers}/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={3}
                            xs={12}
                        >
                            <ProfitPerMonth sx={{ height: '100%' }} profitPerMonth={data?.profitPerMonth}/>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={6}
                            xl={3}
                            xs={12}
                        >
                            <TotalProfit sx={{ height: '100%' }} totalProfit={data?.totalProfit}/>
                        </Grid>
                        {/*<Grid*/}
                        {/*    item*/}
                        {/*    lg={12}*/}
                        {/*    sm={12}*/}
                        {/*    xl={12}*/}
                        {/*    xs={12}*/}
                        {/*>*/}
                        {/*    <div style={{width: 500, height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>*/}
                        {/*        <div style={{width: 500, height: 500, zIndex: 100}} >*/}
                        {/*            <PieDiag/>*/}
                        {/*        </div>*/}
                        {/*        <div className="first">1</div>*/}
                        {/*        <div className="second">2</div>*/}
                        {/*        <div className="third">3</div>*/}
                        {/*        <div className="fouth">4</div>*/}
                        {/*        <div className="fifth">5</div>*/}
                        {/*        <div className="sixth">6</div>*/}
                        {/*        <div className="seventh">7</div>*/}
                        {/*        <div className="eighth">8</div>*/}
                        {/*        <div className="nineth">9</div>*/}
                        {/*        <div className="tenth">10</div>*/}
                        {/*        <div className="eleventh">11</div>*/}
                        {/*        <div className="twelfth">12</div>*/}


                        {/*        <div className="diagram"/>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}



                        {/*<Grid*/}
                        {/*  item*/}
                        {/*  lg={8}*/}
                        {/*  md={12}*/}
                        {/*  xl={9}*/}
                        {/*  xs={12}*/}
                        {/*>*/}
                        {/*  <Sales/>*/}
                        {/*</Grid>*/}
                        {/*<Grid*/}
                        {/*  item*/}
                        {/*  lg={4}*/}
                        {/*  md={6}*/}
                        {/*  xl={3}*/}
                        {/*  xs={12}*/}
                        {/*>*/}
                        {/*  <TrafficByDevice sx={{ height: '100%' }}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid*/}
                        {/*  item*/}
                        {/*  lg={4}*/}
                        {/*  md={6}*/}
                        {/*  xl={3}*/}
                        {/*  xs={12}*/}
                        {/*>*/}
                        {/*  <LatestProducts sx={{ height: '100%' }}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid*/}
                        {/*  item*/}
                        {/*  lg={8}*/}
                        {/*  md={12}*/}
                        {/*  xl={9}*/}
                        {/*  xs={12}*/}
                        {/*>*/}
                        {/*  <LatestOrders/>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};


export default Dashboard;
