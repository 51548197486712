import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography
} from '@material-ui/core';

const Context = React.createContext();

const useConfirm = () => {
    const {
        setOpen,
        setTitle,
        setContent,
        setOnConfirm
    } = useContext(Context);

    return ({ title = '', content = '', onConfirm = () => {} }) => {
        setTitle(title);
        setContent(content);
        setOnConfirm(() => onConfirm);
        setOpen(true);
    };
}

const Confirm = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => () => {});

    return (
        <Context.Provider value={{
          setOpen,
          setTitle,
          setContent,
          setOnConfirm
        }}
        >
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="confirm-dialog"
            >
                <DialogTitle>
                    <Typography color="textPrimary" variant="h4" component="p">
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography color="textPrimary" variant="body1" component="p">
                        {content}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      onClick={() => setOpen(false)}
                      color="primary"
                      sx={{ flex: 1 }}
                    >
                        No
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpen(false);
                        onConfirm();
                      }}
                      color="primary"
                      sx={{ flex: 1 }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {children}
        </Context.Provider>
    );
}

Confirm.propTypes = {
    children: PropTypes.any
}

export { Confirm, useConfirm };
