import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import { apiUrl } from '../../enviroment';
import { useGet } from '../../API/request';
import moment from "moment";

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    wrapAvatar: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex'
    },
    wrapInner: {
        flexDirection: 'row',
        display: 'flex'
    },
    label: {
        width: 200,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: 10
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto'
    },
    panel: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#5664d21c'
    },
    panelInCareington: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#79b3ff1c',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    title: {

        marginBottom: 5,
        fontSize: 22,
        fontFamily: 'Roboto'
    },
    link: {
        color: "#2b5fdc",
    },
    "@media (max-width: 520px)": {
        wrap: {
            flexDirection: 'column',
            display: 'flex'
        },
        text: {
            marginLeft: 10,
            marginTop: 10
        }
    }

});

const PaymentFullInfo = () => {

    const { id } = useParams();
    const [payment, setPayment] = useState({});
    const styles = useStyles();
    const navigate = useNavigate();
    const getU = useGet();

    useEffect(() => {
        getU(`api/payments/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setPayment(resp.data.payment);
                }
            });
    }, []);



    return (
        <>
            <Helmet>
                <title>Payment FullInfo</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Card>
                        <CardContent sx={{ p: 3 }}>
                            <div className={styles.wrapAvatar}>
                                <div>
                                    <h1 className={styles.title}>Price</h1>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Currency:
                                        </div>
                                        <div className={styles.text}>
                                            {payment?.price?.currency || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Amount:
                                        </div>
                                        <div className={styles.text}>
                                            {payment?.price?.amount || '---'}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.wrapAvatar}>
                                <div>
                                    <h1 className={styles.title}>User</h1>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            ID:
                                        </div>
                                        <div className={styles.text}>
                                            {payment?.user?._id || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Email:
                                        </div>
                                        <div className={styles.text}>
                                            {payment?.user?.email || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Name:
                                        </div>
                                        <div className={styles.text}>
                                            {payment?.user?.name || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Surname:
                                        </div>
                                        <div className={styles.text}>
                                            {payment?.user?.surname || '---'}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.wrapAvatar}>
                                <div>
                                    <h1 className={styles.title}>Other information </h1>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Payment ID:
                                        </div>
                                        <div className={styles.text}>
                                            {payment?._id || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Receipt Url:
                                        </div>
                                        <div className={styles.text}>
                                            <a href={payment?.receiptUrl} className={styles.link}>
                                                Link to receipt
                                            </a>
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Payment date:
                                        </div>
                                        <div className={styles.text}>
                                            {moment(payment.createdAt).format('DD.MM.YYYY') || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Payment time:
                                        </div>
                                        <div className={styles.text}>
                                            {moment(payment.createdAt).format('hh.mm') || '---'}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </CardContent>
                        <Divider/>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default PaymentFullInfo;
