// const defaultENV = 'dev'; // local | dev | staging | prod
//
// const baseURLProd = 'https://webteamstorm.ru:3080';
// const baseURLDev = 'https://api.toknowyourself.online';
//
// const ENV = {
//     prod: {
//         apiUrl: baseURLProd
//     },
//     dev: {
//         apiUrl: baseURLDev
//     }
// };
//
// const getEnvVars = () => {
//     return ENV[defaultENV];
// };
//
// export default getEnvVars;

export const apiUrl = process.env.REACT_APP_API_URL;
