import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useGet } from '../../API/request';
import { apiUrl } from '../../enviroment';

const UserList = () => {

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [filterEmail, setFilterEmail] = useState('');
    const [filterId, setFilterId] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const getU = useGet();

    const loadUsers = () => {
        setIsDataLoading(true);

        let endpoint = `api/users?page=${page + 1}&limit=${limit}&search[role]=user`;

        if (filterEmail !== '') {
            endpoint += `&search[email]=${filterEmail}`;
        }

        if (filterId !== '') {
            endpoint += `&search[id]=${filterId}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setUsers(resp.data.users);
                    setCount(resp.data.count);
                }
                setIsDataLoading(false);
            })
            .catch(() => {
                setUsers([]);
                setCount(0);
                setIsDataLoading(false);
            });
    };

    const loadUsersWithArgParams = (searchParams) => {
        setIsDataLoading(true);

        let endpoint = `api/users?page=${page + 1}&limit=${limit}&search[role]=user`;

        if (searchParams.email !== '') {
            endpoint += `&search[email]=${searchParams.email}`;
        }

        if (searchParams.id !== '') {
            endpoint += `&search[id]=${searchParams.id}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setUsers(resp.data.users);
                    setCount(resp.data.count);
                }
                setIsDataLoading(false);
            })
            .catch(() => {
                setUsers([]);
                setCount(0);
                setIsDataLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterEmail = (event) => {
        setFilterEmail(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                email: '',
                id: filterId
            });
        }
    };

    const handleFilterId = (event) => {
        setFilterId(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                email: filterEmail,
                id: ''
            });
        }
    };

    const handleFilter = () => {
        loadUsers();
    };

    useEffect(() => {
        loadUsers();
    }, [page, limit]);

    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box sx={{ marginLeft: 2 }}>
                                        <Link to="/app/users/add">
                                            <Button color="primary" variant="contained">
                                                Add user
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{ pt: 3 }}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{ minWidth: 1050 }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    mx: 2,
                                                    mb: 1
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Email"
                                                        margin="normal"
                                                        name="filterEmail"
                                                        onChange={handleFilterEmail}
                                                        type="text"
                                                        value={filterEmail}
                                                        variant="outlined"
                                                        style={{ width: '45%' }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Id"
                                                        margin="normal"
                                                        name="filterId"
                                                        onChange={handleFilterId}
                                                        type="text"
                                                        value={filterId}
                                                        variant="outlined"
                                                        style={{ width: '45%' }}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={handleFilter}
                                                        sx={{ mt: 2, mb: 1 }}
                                                    >
                                                        Filter
                                                    </Button>
                                                </Box>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ width: 80 }}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Avatar
                                                            </TableCell>
                                                            <TableCell>
                                                                Name
                                                            </TableCell>
                                                            <TableCell>
                                                                Surname
                                                            </TableCell>
                                                            <TableCell>
                                                                Email
                                                            </TableCell>
                                                            {/*<TableCell>*/}
                                                            {/*    Login*/}
                                                            {/*</TableCell>*/}
                                                            <TableCell>
                                                                Role
                                                            </TableCell>
                                                            <TableCell/>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {users.map((user) => (
                                                            <TableRow hover key={user.id}>
                                                                <TableCell sx={{ width: 80 }}>
                                                                    {user.id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                                                        <Avatar
                                                                            src={
                                                                                user.avatar ?
                                                                                    `${apiUrl}/uploads/avatars/${user.avatar}`
                                                                                    :
                                                                                    ''
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.surname}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.email}
                                                                </TableCell>
                                                                {/*<TableCell>*/}
                                                                {/*    {user.login}*/}
                                                                {/*</TableCell>*/}
                                                                <TableCell>
                                                                    {user.role}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{ display: 'flex' }}>
                                                                        <Link to={`/app/user/${user.id}`}>
                                                                            <Button color="primary" variant="contained">
                                                                                Info
                                                                            </Button>
                                                                        </Link>
                                                                        <Box sx={{ ml: 2 }}>
                                                                            <Link to={`/app/user/edit/${user.id}`}>
                                                                                <Button color="primary"
                                                                                        variant="contained">
                                                                                    Edit
                                                                                </Button>
                                                                            </Link>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default UserList;
