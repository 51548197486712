import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    TableFooter,
    TablePagination,
    Card,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useConfirm } from '../../components/confirm';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import TestResultsSkelet from '../../skeletons/TestResultsSkelet';
import { useDelete, useGet, usePut } from '../../API/request';

const TestResultsList = () => {

    const getU = useGet();
    const putU = usePut();
    const confirm = useConfirm();

    const [dataLoading, setDataLoading] = useState(true);
    const [results, setResults] = useState([]);

    const [filterResultId, setFilterResultId] = useState('');
    const [filterTestId, setFilterTestId] = useState('');
    const [filterUserId, setFilterUserId] = useState('');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const sendToEmail = (id) => {
        confirm({
            title: 'Confirmation',
            content: 'Are you sure you want to send this result to the mail?',
            onConfirm: () => {
                putU(`api/tests/proficiency/results/${id}/confirm`)
                    .then((response) => {
                        if (response.status === 'success') {
                            showAlert('success', 'Talent Edited');
                            loadResults();
                        } else {
                            showAlert('error', 'Error occured');
                        }
                    })
                    .catch((err) => {
                        showAlert('error', 'Server error occured');
                    });
            }
        });

    };

    const loadResults = () => {
        let endpoint = `api/tests/proficiency/results?page=${page + 1}&limit=${limit}`;

        if (filterResultId !== '') {
            endpoint += `&id=${filterResultId}`;
        }

        if (filterUserId !== '') {
            endpoint += `&user=${filterUserId}`;
        }

        setDataLoading(true);

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setResults(resp.data.results);
                    setCount(resp.data.count);
                }
                setDataLoading(false);
            });
    };

    const loadResultsWithArgParams = (searchParams) => {
        let endpoint = `api/tests/proficiency/results?page=${page + 1}&limit=${limit}`;


        if (searchParams.resultId !== '') {
            endpoint += `&id=${searchParams.resultId}`;
        }

        if (searchParams.userId !== '') {
            endpoint += `&user=${searchParams.userId}`;
        }

        setDataLoading(true);

        getU(endpoint)
            .then((resp) => {
                console.log(resp);
                if (resp.status === 'success') {
                    setResults(resp.data.results);
                    setCount(resp.data.count);
                }
                setDataLoading(false);
            }).catch(() => {

        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });
        }, 1400);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterResultId = (event) => {
        setFilterResultId(event.target.value);

        if (event.target.value === '') {
            loadResultsWithArgParams({
                resultId: '',
                testId: filterTestId,
                userId: filterUserId
            });
        }
    };

    const handleFilterTestId = (event) => {
        setFilterTestId(event.target.value);

        if (event.target.value === '') {
            loadResultsWithArgParams({
                resultId: filterResultId,
                testId: '',
                userId: filterUserId
            });
        }
    };

    const handleFilterUserId = (event) => {
        setFilterUserId(event.target.value);

        if (event.target.value === '') {
            loadResultsWithArgParams({
                resultId: filterResultId,
                testId: filterTestId,
                userId: ''
            });
        }
    };

    const handleFilter = () => {
        loadResults();
    };


    useEffect(() => {
        loadResults();
    }, [page, limit]);

    return (
        <>
            <Helmet>
                <title>Test results</title>
            </Helmet>

            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>

                <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                    {alert.txt}
                </Alert>

                <Container maxWidth={false}>
                    {
                        dataLoading ?
                            <TestResultsSkelet/>
                            :
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{ minWidth: 1050 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: 2, mb: 1 }}>
                                            <TextField
                                                label="Result id"
                                                margin="normal"
                                                name="filterResultId"
                                                onChange={handleFilterResultId}
                                                type="text"
                                                value={filterResultId}
                                                variant="outlined"
                                                style={{ width: '45%' }}
                                            />
                                            <TextField
                                                label="User id"
                                                margin="normal"
                                                name="filterUserId"
                                                onChange={handleFilterUserId}
                                                type="text"
                                                value={filterUserId}
                                                variant="outlined"
                                                style={{ width: '45%' }}
                                            />
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={handleFilter}
                                                sx={{ mt: 2, mb: 1 }}
                                            >
                                                Filter
                                            </Button>
                                        </Box>
                                        <Divider/>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: 80 }}>
                                                        Result id
                                                    </TableCell>
                                                    <TableCell>
                                                        User id
                                                    </TableCell>
                                                    <TableCell sx={{ width: 120 }}>
                                                        Kind of test
                                                    </TableCell>
                                                    <TableCell>
                                                        User email
                                                    </TableCell>
                                                    <TableCell>
                                                        Status
                                                    </TableCell>
                                                    <TableCell/>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {results?.map((result) => (
                                                    <TableRow hover key={result?._id}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            {result?._id || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {result?.user?._id || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {result?.isAdult ? 'Adult' : 'Child' || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {result?.user?.email || '---'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {result?.status || '---'}
                                                        </TableCell>

                                                        <TableCell>
                                                            <Box sx={{ display: 'flex' }}>

                                                                    <Link color="primary"
                                                                          to={`/app/test-result/edit/${result?._id}`}
                                                                          style={result.status !== 'awaiting:moderation' ? { pointerEvents: 'none' } : {}}

                                                                    >
                                                                        <Button
                                                                            disabled={result.status !== 'awaiting:moderation'}
                                                                            variant="contained"
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                    </Link>

                                                                <Link color="primary"
                                                                      to={`/app/test-result/info/${result?._id}`}
                                                                      style={{marginLeft: "10px"}}
                                                                >
                                                                    <Button
                                                                        variant="contained">
                                                                        Info
                                                                    </Button>
                                                                </Link>
                                                                <Button
                                                                    style={{marginLeft: "10px"}}
                                                                    disabled={result.status !== 'awaiting:moderation'}
                                                                    onClick={() => sendToEmail(result?._id)}
                                                                    variant="contained">
                                                                    Confirm
                                                                </Button>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                    }
                </Container>
            </Box>
        </>
    );
};

export default TestResultsList;
