import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import AdminFormEdit from '../../components/admin/AdminFormEdit';

const AdminEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>Edit user</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false} >
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <AdminFormEdit id={String(id)}/>
                    </Box>
                    {/*<Box sx={{ pt: 3 }}>*/}
                    {/*    <UserSetPassForm id={String(id)} />*/}
                    {/*</Box>*/}
                </Container>
            </Box>
        </>
    );
};

export default AdminEdit;
