import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Alert
} from '@material-ui/core';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import { useGet, usePut } from '../../API/request';

const AccountProfileDetails = (props) => {

    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        login: '',
        password: '',
        confirm: ''
    });
    const [errors, setErrors] = useState({
        password: false,
        confirm: false
    });

    const getU = useGet();
    const putU = usePut();

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [dataBeforeChange, setDataBeforeChange] = useState({});
    const [nothingChanged, setNothingChanged] = useState(true);
    const [changePassword, setChangePassword] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        if (nothingChanged === true) {
            setNothingChanged(false);
        }

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text, hideAlert = true) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        if (hideAlert) {
            setTimeout(() => {
                setAlert({
                    ...alert,
                    isVisible: false
                });

                setSubmitDisabled(false);

                if (type === 'success') {
                    window.location.reload();
                }
            }, 1400);
        }
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.firstName.trim() === '') {
            validComplete = false;
        }

        if (values.lastName.trim() === '') {
            validComplete = false;
        }


        if (values.password) {
            if (!values.password) {
                validComplete = false;
                formErrors.password = true;
            } else if (values.password.length < 6) {
                validComplete = false;
                formErrors.password = true;
            }

            if (values.password !== values.confirm) {
                validComplete = false;
                formErrors.confirm = true;
            }
        }


        if (!validComplete) {
            showAlert('error', 'Type correct data');
        }

        setErrors(formErrors);
        return validComplete;
    };

    const onSubmit = async () => {
        setSubmitDisabled(true);

        if (nothingChanged) {
            showAlert('error', 'Nothing changed');
            return;
        }

        if (validate()) {
            const payload = {
                name: values.firstName,
                surname: values.lastName,
                phoneNumber: values.phoneNumber
            };

            if (values.email !== dataBeforeChange.email) {
                payload.email = values.email;
            }
            if (values.login !== dataBeforeChange.login) {
                payload.login = values.login;
            }

            if (values.password) {
                payload.password = values.password;
            }

            let data = new FormData();
            for (let key in payload) {
                data.append(key, payload[key]);
            }
            const { id } = await TokenStorage.getUser();

            putU(`api/users/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Admin edited');
                    } else {
                        showAlert('error', 'Error occured');
                    }
                })
                .catch((err) => {
                        if (err.response.data.message === 'Пользователь с таким email уже существует') {
                            showAlert('error', 'Admin with such email already exists');
                        } else if (err.response.data.message === 'Пользователь с таким login уже существует') {
                            showAlert('error', 'Admin with such login already exists');
                        } else {
                            showAlert('error', 'Server error occured');
                        }
                    }
                );
        }
    };

    useEffect(async () => {

        getU(`api/users/current`)
            .then((resp) => {
                if (resp.status === 'success') {
                    let userData = resp.data.user;
                    let vals = {
                        firstName: userData.name || '',
                        lastName: userData.surname || '',
                        email: userData.email,
                        phoneNumber: userData.phoneNumber,
                        login: userData.login
                    };
                    let beforeChange = {
                        email: userData.email,
                        login: userData.login
                    };

                    setDataBeforeChange(beforeChange);
                    setValues(vals);
                }
            })
            .catch((err) => {
                showAlert('error', 'Server error occured', false);
            });
    }, []);

    return (
        <form
            autoComplete="off"
            noValidate
            {...props}
        >
            <Card>
                <CardHeader
                    subheader="The information can be edited"
                    title="Profile"
                />
                <Divider/>
                <CardContent>
                    <Grid container spacing={3} marginBottom={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                // helperText="Please specify the first name"
                                label="First name"
                                name="firstName"
                                onChange={handleChange}
                                required
                                value={values.firstName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Last name"
                                name="lastName"
                                onChange={handleChange}
                                required
                                value={values.lastName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Email Address"
                                name="email"
                                onChange={handleChange}
                                required
                                value={values.email}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Phone Number"
                                name="phoneNumber"
                                onChange={handleChange}
                                required
                                value={values.phoneNumber}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Login"
                                name="login"
                                onChange={handleChange}
                                required
                                value={values.login}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Divider/>

                    <Grid container spacing={3} marginTop={0}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Password"
                                name="password"
                                onChange={handleChange}
                                required
                                type="password"
                                value={values.password}
                                variant="outlined"
                                error={errors.password}
                                helperText={errors.password && 'Password shouldn\'t be shorter than 6 characters'}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Confirm"
                                name="confirm"
                                onChange={handleChange}
                                type="password"
                                required
                                value={values.confirm}
                                variant="outlined"
                                error={errors.confirm}
                                helperText={errors.confirm && 'Passwords are different'}
                            />
                        </Grid>
                    </Grid>

                    <Alert severity={alert.type}
                           style={{ display: alert.isVisible ? 'flex' : 'none', margin: '12px 0' }}>
                        {alert.txt}
                    </Alert>
                </CardContent>
                <Divider/>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={submitDisabled}
                        onClick={onSubmit}
                    >
                        Save details
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

export default AccountProfileDetails;
