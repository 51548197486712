import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { apiUrl } from '../../enviroment';
import { useGet } from '../../API/request';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    wrapAvatar: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex',
        marginTop: 10
    },
    wrapInner: {
        flexDirection: 'row',
        display: 'flex',
    },
    label: {
        width: 200,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: 10,
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto'
    },
    panel: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#5664d21c'
    },
    panelInCareington: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#79b3ff1c',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    }
});

const Admin = () => {

    const getU = useGet();
    const { id } = useParams();
    const [user, setUser] = useState({});
    const styles = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        getU(`api/users/${id}`)
        .then((resp) => {
            if (resp.status === 'success') {
                setUser(resp.data.user);
            }
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>User</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Card>
                        <CardContent sx={{ p: 3 }}>
                            <Avatar src={`${apiUrl}/uploads/avatars/${user.avatar}`} style={{ marginLeft: '7px' }} />
                            <div className={styles.wrapAvatar}>
                                <div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                ID:
                                            </div>
                                            <div className={styles.text}>
                                                {user.id || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                First name:
                                            </div>
                                            <div className={styles.text}>
                                                {user.name || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Last name:
                                            </div>
                                            <div className={styles.text}>
                                                {user.surname || '---'}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Email:
                                            </div>
                                            <div className={styles.text}>
                                                {user.email || '---'}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Phone Number:
                                            </div>
                                            <div className={styles.text}>
                                                {user.phoneNumber || '---'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Role:
                                </div>
                                <div className={styles.text}>
                                    {user.role || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Login:
                                </div>
                                <div className={styles.text}>
                                    {user.login || '---'}
                                </div>
                            </div>
                        </CardContent>
                        <Divider />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                            <RouterLink to={`/app/admin/edit/${id}`}>
                                <Button color="primary" variant="contained">
                                    Edit
                                </Button>
                            </RouterLink>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Admin;
