import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import User from './pages/User/User';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import UserAdd from './pages/User/UserAdd';
import EmailTemplateList from './pages/Templates/EmailTemplateList';
import EmailTemplateCreate from './pages/Templates/EmailTemplateCreate';
import EmailTemplateEdit from './pages/Templates/EmailTemplateEdit';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Tests from './pages/Test/Tests';
import TestAdd from './pages/Test/TestAdd';
import TestResultsList from './pages/TestResult/TestResultsList';
import TestResult from './pages/TestResult/TestResult';
import TestPass from './pages/Test/TestPass';
import TestEdit from './pages/Test/TestEdit';
import TestInfo from './pages/Test/TestInfo';
import PaymentList from './pages/Payment/PaymentList';
import TestSettings from './pages/Test/TestSettings';
import PaymentFullInfo from './pages/Payment/PaymentFullInfo';
import TalentsList from './pages/Talents/TalentsList';
import TalentsEdit from './pages/Talents/TalentsEdit';
import TalentAdd from './pages/Talents/TalentAdd';
import TagsList from './pages/Tags/TagsList';
import TagEdit from './pages/Tags/TagEdit';
import TagAdd from './pages/Tags/TagAdd';
import DirectionsList from './pages/Directions/DirectionsList';
import DirectionsEdit from './pages/Directions/DirectionsEdit';
import DirectionAdd from './pages/Directions/DirectionAdd';
import ProfessionList from './pages/Professions/ProfessionList';
import ProfessionEdit from './pages/Professions/ProfessionEdit';
import ProfessionAdd from './pages/Professions/ProfessionAdd';
import FeedBackList from './pages/FeedBacks/FeedBackList';
import FeedBackInfo from './pages/FeedBacks/FeedBackInfo';
import TestResultInfo from './pages/TestResult/TestResultInfo';
import TestResultsEdit from './pages/TestResult/TestResultsEdit';
import Admin from './pages/Admin/Admin';
import AdminAdd from './pages/Admin/AdminAdd';
import AdminEdit from './pages/Admin/AdminEdit';
import AdminList from './pages/Admin/AdminList';
import LanguagesList from './pages/Languages/LanguagesList';
import LanguagesEdit from './pages/Languages/LanguagesEdit';
import LanguagesAdd from './pages/Languages/LanguagesAdd';
import MainPageList from './pages/MainPage/MainPageList';
import MainPageEdit from './pages/MainPage/MainPageEdit';
import MainPageAdd from './pages/MainPage/MainPageAdd';
import OrderConsultationList from './pages/Order/OrderConsultation/OrderConsultationList';
import OrderConsultationEdit from './pages/Order/OrderConsultation/OrderConsultationEdit';
import OrderDiagnosticList from './pages/Order/OrderDiagnostic/OrderDiagnosticList';
import OrderEmailList from './pages/Order/OrdedEmail/OrderEmailList';
import OrderEmailEdit from './pages/Order/OrdedEmail/OrderEmailEdit';
import PriceList from './pages/Payment/Price/PriceList';
import PriceEdit from './pages/Payment/Price/PriceEdit';
import PriceAdd from './pages/Payment/Price/PriceAdd';

const routes = [
    {
        path: 'app',
        element: <DashboardLayout/>,
        children: [
            { path: 'account', element: <Account/> },
            { path: 'user/:id', element: <User/> },
            { path: 'users/add', element: <UserAdd/> },
            { path: 'user/edit/:id', element: <UserEdit/> },
            { path: 'users', element: <UserList/> },

            { path: 'admin/:id', element: <Admin/> },
            { path: 'admin/add', element: <AdminAdd/> },
            { path: 'admin/edit/:id', element: <AdminEdit/> },
            { path: 'admins', element: <AdminList/> },

            { path: 'email-templates', element: <EmailTemplateList/> },
            { path: 'email-templates/create', element: <EmailTemplateCreate/> },
            { path: 'email-templates/edit/:id', element: <EmailTemplateEdit/> },

            { path: 'dashboard', element: <Dashboard/> },

            { path: 'tests', element: <Tests/> },
            { path: 'test/:id', element: <TestInfo/> },
            { path: 'tests/add', element: <TestAdd/> },
            { path: 'tests/pass/:id', element: <TestPass/> },
            { path: 'tests/edit/:id', element: <TestEdit/> },
            { path: 'test-results', element: <TestResultsList/> },
            { path: 'test-result/info/:id', element: <TestResultInfo/> },
            { path: 'test-result/edit/:id', element: <TestResultsEdit/> },

            { path: 'talents', element: <TalentsList/> },
            { path: 'talents/edit/:id', element: <TalentsEdit/> },
            { path: 'talents/add', element: <TalentAdd/> },

            { path: 'tags', element: <TagsList/> },
            { path: 'tags/edit/:id', element: <TagEdit/> },
            { path: 'tags/add', element: <TagAdd/> },

            { path: 'direction', element: <DirectionsList/> },
            { path: 'direction/edit/:id', element: <DirectionsEdit/> },
            { path: 'direction/add', element: <DirectionAdd/> },

            { path: 'professions', element: <ProfessionList/> },
            { path: 'professions/edit/:id', element: <ProfessionEdit/> },
            { path: 'professions/add', element: <ProfessionAdd/> },

            { path: 'feedbacks', element: <FeedBackList/> },
            { path: 'feedbacks/info/:id', element: <FeedBackInfo/> },

            { path: 'payment', element: <PaymentList/> },
            { path: 'payment-fullInfo/:id', element: <PaymentFullInfo/> },

            { path: 'prices', element: <PriceList/> },
            { path: 'price/add', element: <PriceAdd/> },
            { path: 'prices/edit/:id', element: <PriceEdit/> },

            { path: 'test-settings', element: <TestSettings/> },

            { path: 'languages', element: <LanguagesList/> },
            { path: 'languages/edit/:id', element: <LanguagesEdit/> },
            { path: 'languages/add', element: <LanguagesAdd/> },

            { path: 'main-page', element: <MainPageList/> },
            { path: 'main-page/edit/:id', element: <MainPageEdit/> },
            { path: 'main-page/add', element: <MainPageAdd/> },

            { path: 'consultations', element: <OrderConsultationList/> },
            { path: 'consultation/:id', element: <OrderConsultationEdit/> },

            { path: 'diagnostics', element: <OrderDiagnosticList/> },
            { path: 'diagnostic/:id', element: <OrderConsultationEdit/> },

            { path: 'consultation/email', element: <OrderEmailList/> },
            { path: 'consultations/email/edit', element: <OrderEmailEdit/> },

            { path: '*', element: <Navigate to="/404"/> }
        ]
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            { path: 'login', element: <Login/> },
            { path: '404', element: <NotFound/> },
            { path: '/', element: <Navigate to="/app/dashboard"/> },
            { path: '*', element: <Navigate to="/404"/> }
        ]
    }
];

export default routes;
