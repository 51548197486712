import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Avatar,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    CircularProgress,
    Alert,
    Input
} from '@material-ui/core';
import { useGet, usePut } from '../../API/request';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Clear } from '@material-ui/icons';

const DirectionFormAdd = ({...props}) => {

    const putU = usePut();

    const [directions, setDirections] = useState({});

    const [values, setValues] = useState({
        color: '',
        values: [],
        language: ''
    });

    const [errors, setErrors] = useState({
        color: false,
        values: false
    });

    const [isShowLoader, setIsShowLoader] = useState(false);

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };


        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Nothing changed');
            return;
        }

        if (validate()) {
            setSubmitDisabled(true);

            const data = {
                color: values.color,
                language: values.language,
                values: values.values
            };

            setIsShowLoader(true);

            putU(`api/tests/proficiency/directions`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Talent Edited');
                    } else {
                        showAlert('error', 'Error occured');
                    }

                    setIsShowLoader(false);
                })
                .catch((err) => {
                    setIsShowLoader(false);
                    showAlert('error', 'Server error occured');

                });
        }
    };

    const onChangeTag = (e) => {
        setNothingChanged(false);
        setDirections({ ...directions, [e.target.name]: e.target.value });
        setValues({ ...values, values: Object.values(directions) });

    };

    const deleteTag = async (index) => {
        values.values.splice(index, 1);
        let reduce = values.values.reduce((a, v, i) => ({ ...a, [i + 1]: v }), {});
        setDirections(reduce);

    };

    const addOneMoreTalent = () => {
        setDirections({ ...directions, [values.values.length + 1]: '' });
    };


    return (
        <form>
            <Card>
                <CardHeader
                    title="Add direction"
                />
                <Divider/>
                <CardContent sx={{ position: 'relative' }}>
                    {isShowLoader && (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#ffffff',
                            zIndex: 5
                        }}
                        >
                            <CircularProgress/>
                        </Box>
                    )}
                    <TextField
                        fullWidth
                        label="Color"
                        margin="normal"
                        name="color"
                        onChange={handleChange}
                        type="text"
                        value={values.color}
                        variant="outlined"
                        error={errors.color}
                    />
                    {
                        Object.values(directions)?.map((item, index) => {
                                return (
                                    <TextField
                                        key={index}
                                        fullWidth
                                        multiline
                                        label={index + 1}
                                        margin="normal"
                                        name={index + 1}
                                        onChange={onChangeTag}
                                        type="text"
                                        value={item}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end"
                                                                sx={{ cursor: 'pointer' }}
                                                                onClick={() => deleteTag(index)}
                                                >
                                                    <Clear/>
                                                </InputAdornment>
                                            )
                                        }}
                                        // error={errors.values}
                                    />

                                );
                            }
                        )
                    }
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={addOneMoreTalent}
                            disabled={submitDisabled}
                        >
                            Add direction
                        </Button>
                    </Box>

                    <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                        {alert.txt}
                    </Alert>
                </CardContent>


                <Divider/>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={submit}
                        disabled={submitDisabled}
                    >
                        Save
                    </Button>
                </Box>
            </Card>
        </form>
    );
};


export default DirectionFormAdd;
