import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { apiUrl } from '../../enviroment';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useGet } from '../../API/request';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import TestResult from './TestResult';
import PieDiag from '../../components/PieDiag';
import { BallTriangle } from 'react-loader-spinner';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Typography from '@material-ui/core/Typography';
import '../../styles/Directions/Directions.css';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    wrapAvatar: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex'
    },
    wrapInner: {
        flexDirection: 'row',
        display: 'flex'
    },
    label: {
        width: 200,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: 10
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto'
    },
    panel: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#5664d21c'
    },
    panelInCareington: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#79b3ff1c',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    listWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        maxWidth: 600
    },
    list: {
        fontSize: 18,
        fontFamily: 'Roboto',
        paddingBottom: 5
    },
    professionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: 700
    },
    keys: {
        fontSize: 18,
        fontFamily: 'Roboto',
        paddingBottom: 5,
        marginRight: 15
    },
    values: {
        paddingBottom: 15
    },
    valuesElement: {
        fontSize: 18,
        fontFamily: 'Roboto',
        marginBottom: 5
    },
    chart: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: 20
    }
});

const TestResultInfo = () => {

    const getU = useGet();
    const { id } = useParams();
    const [result, setResult] = useState({});
    const [professions, setProfessions] = useState([]);
    const [directions, setDirections] = useState([]);
    const [talants, setTalants] = useState([]);
    const styles = useStyles();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const diagramsNumb = result?.order;


    useEffect(() => {
        setIsLoading(true);
        getU(`api/tests/proficiency/results/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    console.log(resp.data);
                    setResult(resp.data);
                    setDirections(resp.data.result.directions);
                    setProfessions(resp.data.result.professions);
                    setTalants(resp.data.result.talents);
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);


    if (isLoading) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }


    return (
        <>
            <Helmet>
                <title>Payment FullInfo</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>

            <Box sx={{ backgroundColor: 'background.default', minHeight: 1300 }}>
                <Container maxWidth={false}>
                    <Card>
                        <CardContent sx={{ p: 3 }}>
                            <div className={styles.wrapAvatar}>
                                <div>
                                    <h1 className={styles.title}>User info</h1>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            ID:
                                        </div>
                                        <div className={styles.text}>
                                            {result?.user?._id || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Email:
                                        </div>
                                        <div className={styles.text}>
                                            {result?.user?.email || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Name:
                                        </div>
                                        <div className={styles.text}>
                                            {result?.user?.name || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Surname:
                                        </div>
                                        <div className={styles.text}>
                                            {result?.user?.surname || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Language:
                                        </div>
                                        <div className={styles.text}>
                                            {result?.language?.name || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Payment ID:
                                        </div>
                                        <div className={styles.text}>
                                            {result?.payment || '---'}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.wrapAvatar}>
                                <div>
                                    <h1 className={styles.title}>Test</h1>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            ID:
                                        </div>
                                        <div className={styles.text}>
                                            {result?._id || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            User Type::
                                        </div>
                                        <div className={styles.text}>
                                            {result?.isAdult ? 'Adult' : 'Child' || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            Created date:
                                        </div>
                                        <div className={styles.text}>
                                            {moment(result.createdAt).format('HH:mm, DD.MM.YYYY') || '---'}
                                        </div>
                                    </div>
                                    <div className={styles.wrap}>
                                        <div className={styles.label}>
                                            User order:
                                        </div>
                                        <div className={styles.text}>
                                            {
                                                result?.order?.map((item, index) =>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ marginRight: 10 }}>
                                                            {
                                                                item.order
                                                            }
                                                        </div>
                                                        <div style={{ marginRight: 10 }}>
                                                            {
                                                                item.color
                                                            }
                                                        </div>
                                                        <div className={item.color === 'кристалл'
                                                            ? 'colorCodeForСrystal'
                                                            : 'colorCode'
                                                        } style={{ backgroundColor: item.colorCode }}/>

                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={styles.chart}>
                                <div style={{
                                    width: 500,
                                    height: 500,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative'
                                }}>
                                    {
                                        diagramsNumb.map((item) => {
                                            return (
                                                item.order === 13
                                                    ? <div className="diagram"
                                                            style={{background: `${item.colorCode}`}}
                                                    />
                                                    : <div className={`piece${item.order}`} style={{
                                                        color: item.colorCode === "#FFFFFF" ? "#000": "#FFFFFF"
                                                    }}>
                                                        {
                                                            item.order
                                                        }
                                                    </div>
                                            );
                                        })
                                    }
                                    <div style={{ width: 500, height: 500, zIndex: 100 }}>
                                        <PieDiag/>
                                    </div>
                                </div>
                            </div>


                        </CardContent>
                        <Divider/>
                    </Card>
                </Container>

            </Box>

            <Box sx={{ backgroundColor: 'background.default', py: 1 }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 3 }}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{ minWidth: 1050 }}>
                                    <Typography
                                        sx={{ p: 2 }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        Directions
                                    </Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    Object.entries(directions)?.map(([key, values], index) =>
                                                        <TableCell style={key === 'черный' ? {
                                                            backgroundColor: values.colorCode,
                                                            color: '#fff'
                                                        } : { backgroundColor: values.colorCode }}
                                                                   className={key === 'кристалл'
                                                                       ? 'crystalTable'
                                                                       : 'colorCode'
                                                                   }
                                                        >
                                                            {key + ' '}
                                                            {values.weight}
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {
                                                    Object.entries(directions)?.map(([key, values], index) =>

                                                        <TableCell>
                                                            {values.values.length === 0
                                                                ? <p>
                                                                    ----
                                                                </p>
                                                                : values.values?.map((item, index) =>
                                                                    <p>
                                                                        {
                                                                            index + 1 + '. ' + item
                                                                        }
                                                                    </p>
                                                                )
                                                            }
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

            <Box sx={{ backgroundColor: 'background.default', py: 1 }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 3 }}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{ minWidth: 1050 }}>
                                    <Typography
                                        sx={{ p: 2 }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        Professions
                                    </Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    Object.entries(professions)?.map(([key, values], index) =>
                                                        <TableCell style={key === 'черный'
                                                            ? {
                                                                backgroundColor: values.colorCode,
                                                                color: '#fff'
                                                            }
                                                            : { backgroundColor: values.colorCode }}
                                                                   className={key === 'кристалл'
                                                                       ? 'crystalTable'
                                                                       : 'colorCode'
                                                                   }
                                                        >

                                                            {key + ' '}
                                                            {values.weight}
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {
                                                    Object.entries(professions)?.map(([key, values], index) =>

                                                        <TableCell>
                                                            {values.values.length === 0
                                                                ? <p>
                                                                    ----
                                                                </p>
                                                                : values.values?.map((item, index) =>
                                                                    <p>
                                                                        {
                                                                            index + 1 + '. ' + item
                                                                        }
                                                                    </p>
                                                                )
                                                            }
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

            <Box sx={{ backgroundColor: 'background.default', py: 1 }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 3 }}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{ minWidth: 1050, p: 5 }}>
                                    <Typography
                                        sx={{ p: 2 }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        Talents
                                    </Typography>
                                    {
                                        talants?.map((item, index) =>
                                            <p>
                                                {
                                                    index + 1 + '.' + item + ' '
                                                }
                                            </p>
                                        )
                                    }
                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

        </>
    );
};

export default TestResultInfo;
