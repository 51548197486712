import React, { useEffect, useState } from 'react';
import { useGet, usePut } from '../../API/request';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress, Container,
    Divider,
    TextField
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Clear } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { ChevronDown, ChevronsDown, ChevronUp } from 'react-feather';
import Typography from '@material-ui/core/Typography';
import '../../styles/Directions/Directions.css';

const TestResultsEdit = () => {

    const getU = useGet();
    const putU = usePut();
    const { id } = useParams();
    const navigate = useNavigate();


    const [directions, setDirections] = useState({});
    const [talents, setTalents] = useState({});
    const [profession, setProfession] = useState({});

    const [dataToSendTalents, setDataToSendTalents] = useState([]);
    const [dataToSendDirection, setDataToSendDirection] = useState([]);


    const [showDirections, setShowDirections] = useState(false);
    const [showTalent, setShowTalent] = useState(false);
    const [showProfession, setShowProfession] = useState(false);

    const [values, setValues] = useState({
        professions: {},
        talents: [],
        directions: {}
    });

    const [errors, setErrors] = useState({
        professions: false,
        talents: false,
        directions: false
    });

    const [isShowLoader, setIsShowLoader] = useState(false);

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const [dataBeforeChange, setDataBeforeChange] = useState({});


    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };


        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        // if (nothingChanged) {
        //     showAlert('error', 'Nothing changed');
        //     return;
        // }

        if (validate()) {
            setSubmitDisabled(true);
            const data = {
                professions: profession,
                talents: dataToSendTalents,
                directions: directions
            };

            setIsShowLoader(true);


            putU(`api/tests/proficiency/results/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Talent Edited');
                    } else {
                        showAlert('error', 'Error occured');
                    }

                    setIsShowLoader(false);
                })
                .catch((err) => {
                    setIsShowLoader(false);
                    showAlert('error', 'Server error occured');

                });
        }
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    //dilections
    const onChangeDirection = (e) => {
        setNothingChanged(false);

        let split = e.target.value.split(/\s*,\s*/);

        let filter = directions[e.target.name];
        filter.values = split;
        setDirections({ ...directions, [e.target.name]: filter });

        setValues({ ...values, directions: Object.values(directions) });
    };

    //profession
    const onChangeProfession = (e) => {
        setNothingChanged(false);

        let split = e.target.value.split(/\s*,\s*/);

        let filter = profession[e.target.name];
        filter.values = split;
        setProfession({ ...profession, [e.target.name]: filter });

        setValues({ ...values, directions: Object.values(profession) });
    };

    //talents
    const onChangeTalent = (e) => {
        setNothingChanged(false);

        setTalents({ ...talents, [e.target.name]: e.target.value });

        setValues({ ...values, talents: Object.values({ ...talents, [e.target.name]: e.target.value }) });

        setDataToSendTalents(Object.values({ ...talents, [e.target.name]: e.target.value }));

    };
    const deleteTalent = async (index) => {
        setNothingChanged(false);

        values.talents.splice(index, 1);

        let reduce = values.talents.reduce((a, v, i) => ({ ...a, [i + 1]: v }), {});

        setTalents(reduce);

        console.log(reduce)

        setDataToSendTalents(Object.values(reduce));


    };

    const addOneMoreTalent = async () => {

        await setTalents({ ...talents, [values.talents.length + 1]: '' });

        await setValues({ ...values, talents: Object.values({ ...talents, [values.talents.length + 1]: '' }) });

    };


    useEffect(() => {
        getU(`api/tests/proficiency/results/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    let result = resp.data.result;

                    console.log(resp.data);

                    // setDirections(resp.data.newDiractions);

                    let vals = {
                        professions: result.professions,
                        directions: result.directions,
                        talents: result.talents
                    };

                    let reduceTalents = result.talents.reduce((a, v, i) => ({ ...a, [i + 1]: v }), {});

                    setDataToSendTalents(result.talents);
                    setTalents(reduceTalents);
                    setProfession(result.professions);
                    setDirections(result.directions);

                    setValues(vals);
                }
            });
    }, []);


    return (
        <>
            <Helmet>
                <title>Edit talents</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>

            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>

                {/*directions*/}
                <Box>
                    <Container maxWidth={false}
                               onClick={() => setShowDirections(!showDirections)}
                               sx={{ cursor: 'pointer' }}
                    >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: '#fff',
                            justifyContent: 'space-between',
                            padding: 2,
                            marginBottom: 1
                        }}>
                            <Typography
                                variant="h4"
                                color="text.secondary"
                            >
                                Directions
                            </Typography>
                            {showDirections
                                ? <ChevronUp/>
                                : <ChevronDown/>
                            }
                        </Box>
                    </Container>

                    {showDirections &&
                    <Container maxWidth={false}>
                        <Box sx={{ paddingBottom: 1 }}>
                            <form>
                                <Card>
                                    <CardContent sx={{ position: 'relative' }}>
                                        {isShowLoader && (
                                            <Box sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: '#ffffff',
                                                zIndex: 5
                                            }}
                                            >
                                                <CircularProgress/>
                                            </Box>
                                        )}
                                        {
                                            Object.entries(directions)?.map(([key, values], index) => {

                                                    return (

                                                        <div key={key}>
                                                            <div className="directionDescWrapper">
                                                                <div style={{ backgroundColor: values.colorName }}
                                                                     className="colorName">
                                                                    {
                                                                        'Client order: ' + values.order
                                                                    }
                                                                </div>
                                                                <div style={{ backgroundColor: values.colorName }}
                                                                     className="colorName">
                                                                    {
                                                                        'Color: ' + key
                                                                    }
                                                                </div>
                                                                <div style={{ backgroundColor: values.colorCode }}
                                                                     className={key === 'кристалл'
                                                                         ? 'colorCodeForСrystal'
                                                                         : 'colorCode'
                                                                     }
                                                                />
                                                            </div>
                                                            <div>
                                                                {
                                                                    values.weight === null
                                                                        ? <TextField
                                                                            disabled
                                                                            key={index}
                                                                            fullWidth
                                                                            multiline
                                                                            label={"Empty"}
                                                                            margin="normal"
                                                                            name={key}
                                                                            onChange={onChangeDirection}
                                                                            type="text"
                                                                            value={"Empty"}
                                                                            variant="outlined"
                                                                        />
                                                                        : <TextField
                                                                            key={index}
                                                                            fullWidth
                                                                            multiline
                                                                            label={values.weight}
                                                                            margin="normal"
                                                                            name={key}
                                                                            onChange={onChangeDirection}
                                                                            type="text"
                                                                            value={values.values}
                                                                            variant="outlined"
                                                                        />
                                                                }


                                                            </div>
                                                        </div>

                                                    );
                                                }
                                            )
                                        }
                                    </CardContent>
                                </Card>
                            </form>
                        </Box>

                    </Container>
                    }
                </Box>

                {/*talents*/}
                <Box>
                    <Container maxWidth={false}
                               onClick={() => setShowTalent(!showTalent)}
                               sx={{ cursor: 'pointer' }}
                    >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: '#fff',
                            justifyContent: 'space-between',
                            padding: 2,
                            marginBottom: 1
                        }}>
                            <Typography
                                variant="h4"
                                color="text.secondary"
                            >
                                Talents
                            </Typography>
                            {showTalent
                                ? <ChevronUp/>
                                : <ChevronDown/>
                            }
                        </Box>
                    </Container>

                    {showTalent &&
                    <Container maxWidth={false}>
                        <Box sx={{ paddingBottom: 1 }}>
                            <form>
                                <Card>
                                    <CardContent sx={{ position: 'relative' }}>
                                        {isShowLoader && (
                                            <Box sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: '#ffffff',
                                                zIndex: 5
                                            }}
                                            >
                                                <CircularProgress/>
                                            </Box>
                                        )}
                                        {
                                            Object.values(talents)?.map((item, index) => {
                                                    return (
                                                        <TextField
                                                            key={index}
                                                            fullWidth
                                                            multiline
                                                            label={index + 1}
                                                            margin="normal"
                                                            name={`${index + 1}`}
                                                            onChange={onChangeTalent}
                                                            type="text"
                                                            value={item}
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end"
                                                                                    sx={{ cursor: 'pointer' }}
                                                                                    onClick={() => deleteTalent(index)}
                                                                    >
                                                                        <Clear/>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            // error={errors.values}
                                                        />

                                                    );
                                                }
                                            )
                                        }
                                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                            <Button
                                                // disabled={true}
                                                color="primary"
                                                variant="contained"
                                                onClick={addOneMoreTalent}
                                                disabled={submitDisabled}
                                            >
                                                Add one more
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </form>
                        </Box>

                    </Container>
                    }
                </Box>

                <Box>
                    <Container maxWidth={false}
                               onClick={() => setShowProfession(!showProfession)}
                               sx={{ cursor: 'pointer' }}
                    >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: '#fff',
                            justifyContent: 'space-between',
                            padding: 2,
                            marginBottom: 1
                        }}>
                            <Typography
                                variant="h4"
                                color="text.secondary"
                            >
                                Professions
                            </Typography>
                            {showProfession
                                ? <ChevronUp/>
                                : <ChevronDown/>
                            }
                        </Box>
                    </Container>

                    {showProfession &&
                    <Container maxWidth={false}>
                        <Box sx={{ paddingBottom: 1 }}>
                            <form>
                                <Card>
                                    <CardContent sx={{ position: 'relative' }}>
                                        {isShowLoader && (
                                            <Box sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: '#ffffff',
                                                zIndex: 5
                                            }}
                                            >
                                                <CircularProgress/>
                                            </Box>
                                        )}
                                        {
                                            Object.entries(profession)?.map(([key, values], index) => {

                                                    return (

                                                        <div key={key}>
                                                            <div className="directionDescWrapper">
                                                                <div style={{ backgroundColor: values.colorName }}
                                                                     className="colorName">
                                                                    {
                                                                        'Client order: ' + values.order
                                                                    }
                                                                </div>
                                                                <div style={{ backgroundColor: values.colorName }}
                                                                     className="colorName">
                                                                    {
                                                                        'Color: ' + key
                                                                    }
                                                                </div>
                                                                <div style={{ backgroundColor: values.colorCode }}
                                                                     className={key === 'кристалл'
                                                                         ? 'colorCodeForСrystal'
                                                                         : 'colorCode'
                                                                     }
                                                                />
                                                            </div>
                                                            <div>
                                                                {
                                                                    values.weight === 'null'
                                                                        ? <TextField
                                                                            disabled
                                                                            key={index}
                                                                            fullWidth
                                                                            multiline
                                                                            label={values.weight}
                                                                            margin="normal"
                                                                            name={key}
                                                                            onChange={onChangeProfession}
                                                                            type="text"
                                                                            value={values.values}
                                                                            variant="outlined"
                                                                        />
                                                                        : <TextField
                                                                            key={index}
                                                                            fullWidth
                                                                            multiline
                                                                            label={values.weight}
                                                                            margin="normal"
                                                                            name={key}
                                                                            onChange={onChangeProfession}
                                                                            type="text"
                                                                            value={values.values}
                                                                            variant="outlined"
                                                                        />
                                                                }


                                                            </div>
                                                        </div>

                                                    );
                                                }
                                            )
                                        }
                                        {/*{*/}
                                        {/*    Object.entries(profession)?.map(([key, values], index) => {*/}
                                        {/*            return (*/}
                                        {/*                <TextField*/}
                                        {/*                    key={index}*/}
                                        {/*                    fullWidth*/}
                                        {/*                    multiline*/}
                                        {/*                    label={key}*/}
                                        {/*                    margin="normal"*/}
                                        {/*                    name={key}*/}
                                        {/*                    onChange={onChangeProfession}*/}
                                        {/*                    type="text"*/}
                                        {/*                    value={values}*/}
                                        {/*                    variant="outlined"*/}
                                        {/*                    // InputProps={{*/}
                                        {/*                    //     endAdornment: (*/}
                                        {/*                    //         <InputAdornment position="end"*/}
                                        {/*                    //                         sx={{ cursor: 'pointer' }}*/}
                                        {/*                    //                         onClick={() => console.log(index)}*/}
                                        {/*                    //         >*/}
                                        {/*                    //             <Clear/>*/}
                                        {/*                    //         </InputAdornment>*/}
                                        {/*                    //     )*/}
                                        {/*                    // }}*/}
                                        {/*                />*/}
                                        {/*            );*/}
                                        {/*        }*/}
                                        {/*    )*/}
                                        {/*}*/}
                                        {/*<Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>*/}
                                        {/*    <Button*/}
                                        {/*        color="primary"*/}
                                        {/*        variant="contained"*/}
                                        {/*        onClick={() => console.log("123")}*/}
                                        {/*        disabled={submitDisabled}*/}
                                        {/*    >*/}
                                        {/*        Add one more*/}
                                        {/*    </Button>*/}
                                        {/*</Box>*/}
                                    </CardContent>
                                </Card>
                            </form>
                        </Box>

                    </Container>
                    }
                </Box>

                <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                    {alert.txt}
                </Alert>

                <Box sx={{ marginRight: 3, marginLeft: 3, marginTop: 3, paddingBottom: 3 }}>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={submit}
                        disabled={submitDisabled}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default TestResultsEdit;
