import {createSlice} from '@reduxjs/toolkit';
import {configureTransport} from "../../API/transport";
import {post} from "../../API/request";

const initialState = {
    ava: '',
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setAva: (state, action) => {
            state.ava = action.payload
        },

    },
});

export const {setAva} = dashboardSlice.actions;

export default dashboardSlice.reducer;
