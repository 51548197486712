import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    CircularProgress,
    Container, FormControl, InputLabel, Select, MenuItem, Alert
} from '@material-ui/core';
import EmailEditor from 'react-email-editor';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import EmailTemplateAlert from '../../components/email-template/EmailTemplateAlert';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import { useGet, usePost } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';

const EmailTemplateCreate = () => {

    const postU = usePost();
    const getU = useGet();

    const [languages, setLanguages] = useState([]);

    const [values, setValues] = useState({
        key: '',
        subject: ''
    });
    const [errors, setErrors] = useState({
        key: false,
        subject: false
    });
    const [alert, setAlert] = useState({
        status: '',
        message: ''
    });

    const [language, setLanguage] = useState('');

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(true)

    const emailEditorRef = useRef(null);
    const navigate = useNavigate();


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const exportHtml = (callback) => emailEditorRef.current.editor.exportHtml((data) => callback(data.html, data.design));

    const showAlert = (status, msg) => {
        setAlert({
            status,
            message: msg
        });

        setTimeout(() => {
            setAlert({
                status: '',
                message: ''
            });
            setSubmitDisabled(false);
        }, 1500);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.key.trim() === '') {
            validComplete = false;
            formErrors.key = true;
        }

        if (values.subject.trim() === '') {
            validComplete = false;
            formErrors.subject = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const create = () => {
        if (validate()) {
            exportHtml((html, design) => {

                if (!(html && design)) {
                    showAlert('error', 'Error: wait, email editor isn\'t ready');
                    return;
                }

                const payload = {
                    subject: values.subject,
                    key: values.key,
                    draft: design,
                    html,
                    language: language,
                };

                setSubmitDisabled(true);
                setShowLoader(true);

                postU('api/templates/email', payload)
                    .then((resp) => {

                        setShowLoader(false);

                        if (resp.status === 'success') {
                            showAlert('success', 'Template successfully added');
                        } else {
                            if (resp.message === 'Переданны неверные данные') {
                                showAlert('error', 'Error: incorrect data');
                            } else if (resp.message === 'Шаблон с таким ключем уже существует') {
                                showAlert('error', 'Error: template with such a key already exists');
                            } else {
                                showAlert('error', 'Error: template wasn\'t added');
                            }
                        }

                    })
                    .catch((err) => {
                        console.log(err.response)
                        setShowLoader(false);
                        showAlert('error', 'Server error');
                    });
            });
        }
    };

    useEffect(() =>{
        setIsLoading(true)
        getU('api/languages')
            .then((resp) =>{
                if (resp.statuss === 'success') {
                    setLanguages(resp.data.languages);
                    setLanguage(resp.data.languages[0]?._id)
                }
            })
            .catch((e) =>{
                showAlert('error', e?.response?.data.message);
            })
            .finally(() =>{
                setIsLoading(false)
            })
    }, [])

    if (isLoading){
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        )
    }

    return (
        <>
            <Box sx={{ pt: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <form>
                <Card sx={{ m: 3 }}>
                    <CardHeader
                        subheader="Create email template"
                        title="Email template"
                    />
                    <Divider/>
                    <CardContent>
                        <TextField
                            fullWidth
                            label="Key"
                            margin="normal"
                            name="key"
                            onChange={handleChange}
                            type="text"
                            value={values.key}
                            variant="outlined"
                            error={errors.key}
                            helperText={errors.key && 'Type template key'}
                        />
                        <TextField
                            fullWidth
                            label="Subject"
                            margin="normal"
                            name="subject"
                            onChange={handleChange}
                            type="text"
                            value={values.subject}
                            variant="outlined"
                            error={errors.subject}
                            helperText={errors.subject && 'Type template subject'}
                        />
                        <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                            <InputLabel id="language">Language</InputLabel>
                            <Select
                                labelId="language"
                                name="language"
                                value={language}
                                label="Language"
                                onChange={(evt) => setLanguage(evt.target.value)}
                            >
                                {
                                    languages?.map((item) =>
                                        <MenuItem value={item._id}>{item?.title}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                        <Alert severity={alert.type}
                               style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                            {alert.txt}
                        </Alert>
                    </CardContent>
                </Card>
                <Box sx={{ mx: 3, mt: 1, mb: 3 }}>
                    <EmailTemplateAlert status={alert.status} message={alert.message}
                                        onClose={() => setAlert({ status: '', message: '' })}/>
                </Box>
                <Box sx={{ m: 3, flex: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button color="primary" variant="outlined" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CircularProgress style={{ marginRight: '16px', display: showLoader ? 'block' : 'none' }}/>
                            <Button color="primary" variant="contained" onClick={create} disabled={submitDisabled}>
                                Create
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardContent>
                                <EmailEditor ref={emailEditorRef} minHeight="75vh"/>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </form>
        </>
    );
};

export default EmailTemplateCreate;
