import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Typography, Divider, CardHeader, Grid, Alert
} from '@material-ui/core';
import { Link as RouterLink, Link } from 'react-router-dom';
import { useDelete, useGet, usePost, usePut } from '../../API/request';
import Card from '@material-ui/core/Card';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { BallTriangle } from 'react-loader-spinner';


const TestSettings = () => {

    const getU = useGet();
    const postU = usePost();
    const putU = usePut();

    const [isLoading, setIsLoading] = useState(true)

    const isMounted = useRef(false);
    const isMountedWatchProfessionInEmailSend = useRef(false);

    const [verification, setVerification] = useState('manual');
    const [watchProfessionInEmailSend, setWatchProfessionInEmailSend] = useState('yes');

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 1400);
    };

    useEffect(() => {
        setIsLoading(true)
        getU(`api/settings`)
            .then((response) => {
                if (response.status === 'success') {

                    if (response.data.settings.proficiencyManualMode) {
                        setVerification('manual');
                    } else {
                        setVerification('auto');
                    }

                    if (response.data.settings.watchProfessionInEmailSend) {
                        setWatchProfessionInEmailSend("yes");
                    } else {
                        setWatchProfessionInEmailSend("no");
                    }
                }
            })
            .catch(() =>{

            })
            .finally(() =>{
                setIsLoading(false)

            })

    }, []);

    useEffect(() => {

        if (isMounted) {

            if (verification === "manual") {
                putU(`api/settings`, { proficiencyManualMode: true, })
                    .then((response) => {
                        if (response.status === 'success') {
                        } else {
                            showAlert('error', 'Error occured');
                        }
                    });

            } else {
                putU(`api/settings`, { proficiencyManualMode: false, })
                    .then((response) => {
                        if (response.status === 'success') {
                        } else {
                            showAlert('error', 'Error occured');
                        }
                    });

            }

        }

        isMounted.current = true;

    }, [verification]);


    useEffect(() => {

        if (isMountedWatchProfessionInEmailSend) {

            if (watchProfessionInEmailSend === "yes") {
                putU(`api/settings`, { watchProfessionInEmailSend: true})
                    .then((response) => {
                        if (response.status === 'success') {
                        } else {
                            showAlert('error', 'Error occured');
                        }
                    });

            } else {
                putU(`api/settings`, { watchProfessionInEmailSend: false})
                    .then((response) => {
                        if (response.status === 'success') {
                        } else {
                            showAlert('error', 'Error occured');
                        }
                    });

            }

        }

        isMountedWatchProfessionInEmailSend.current = true;

    }, [watchProfessionInEmailSend]);


    if (isLoading){
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Test settings</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Card>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <FormControl sx={{ marginTop: 5, marginBottom: 5, marginLeft: 5 }}>
                                <FormLabel id="radioButton">Select the verification method</FormLabel>
                                <RadioGroup
                                    row
                                    value={verification}
                                    name="radioButton"
                                    onChange={(e) => setVerification(e.target.value)}
                                >
                                    <FormControlLabel value="manual" control={<Radio/>} label="Manual"/>
                                    <FormControlLabel value="auto" control={<Radio/>} label="Auto"/>
                                </RadioGroup>
                            </FormControl>

                            <FormControl sx={{marginBottom: 5, marginLeft: 5 }}>
                                <FormLabel id="watchProfessionInEmailSend">Output of professions in an email message</FormLabel>
                                <RadioGroup
                                    row
                                    value={watchProfessionInEmailSend}
                                    name="watchProfessionInEmailSend"
                                    onChange={(e) => setWatchProfessionInEmailSend(e.target.value)}
                                >
                                    <FormControlLabel value="yes" control={<Radio/>} label="True"/>
                                    <FormControlLabel value="no" control={<Radio/>} label="False"/>
                                </RadioGroup>
                            </FormControl>

                            <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                {alert.txt}
                            </Alert>

                            <Divider/>
                            <CardHeader
                                title="Settings"
                            />

                            <Grid
                                container
                                direction="column"
                                spacing={2}
                                sx={{
                                    ml: 15,
                                    mb: 2
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={12}

                                >
                                    <RouterLink to={`/app/talents`}>
                                        <Button color="primary" variant="contained" sx={{ width: 120 }}>
                                            Talants
                                        </Button>
                                    </RouterLink>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                >
                                    <RouterLink to={`/app/tags`}>
                                        <Button color="primary" variant="contained" sx={{ width: 120 }}>
                                            Tags
                                        </Button>
                                    </RouterLink>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                >
                                    <RouterLink to={`/app/direction`}>
                                        <Button color="primary" variant="contained" sx={{ width: 120 }}>
                                            Direction
                                        </Button>
                                    </RouterLink>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                >
                                    <RouterLink to={`/app/professions`}>
                                        <Button color="primary" variant="contained" sx={{ width: 120 }}>
                                            Professions
                                        </Button>
                                    </RouterLink>
                                </Grid>

                            </Grid>


                        </Box>

                    </Card>
                    {/*{*/}
                    {/*    dataLoading ?*/}
                    {/*        <EmailTemplatesSkelet />*/}
                    {/*        :*/}
                    {/*        <>*/}
                    {/*            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>*/}
                    {/*                <Link to="/app/email-templates/create">*/}
                    {/*                    <Button color="primary" variant="contained">*/}
                    {/*                        Add email template*/}
                    {/*                    </Button>*/}
                    {/*                </Link>*/}
                    {/*            </Box>*/}
                    {/*            <Box sx={{ pt: 3 }}>*/}
                    {/*                {*/}
                    {/*                    emailTemplates.length > 0 ?*/}
                    {/*                        <EmailTemplateListResults templates={emailTemplates} onDelete={onDelete} />*/}
                    {/*                        :*/}
                    {/*                        <Typography color="textPrimary" variant="h2">*/}
                    {/*                            Templates were not loaded*/}
                    {/*                        </Typography>*/}
                    {/*                }*/}
                    {/*            </Box>*/}
                    {/*        </>*/}
                    {/*}*/}
                </Container>
            </Box>
        </>
    );
};

export default TestSettings;
