import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Button,
    Divider,
    Grid,
    Alert,
    Typography,
    Modal
} from '@material-ui/core';
import { X as CrossIcon } from 'react-feather';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';

const TestPass = () => {
    const [buttonsAmount, setButtonsAmount] = useState(13);
    const [chosenBtns, setChosenBtns] = useState([]);
    const [btnsClicked, setBtnsClicked] = useState(new Array(buttonsAmount).fill(false));
    const [testName, setTestName] = useState('Test name');
    const [testValues, setTestValues] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTxt, setModalTxt] = useState('');

    const { id } = useParams();

    function testBtnClicked(event) {
        let clicked = event.target;
        let clickedArr = [...btnsClicked];

        if (clicked.tagName === 'BUTTON') {
            let chosen = [...chosenBtns];
            let ind = clicked.getAttribute('index');
            chosen.push({
                clickedInd: ind,
                variantName: clicked.getAttribute('text')
            });
            clickedArr[ind] = true;
            setChosenBtns(chosen);
            setBtnsClicked(clickedArr);
        }
    }

    function removeVariant(chosenInd, btnInd) {
        let chosen = [...chosenBtns];
        let clickedArr = [...btnsClicked];

        chosen.splice(chosenInd, 1);
        clickedArr[btnInd] = false;

        setChosenBtns(chosen);
        setBtnsClicked(clickedArr);
    }

    function sendTest() {
        let results = [];

        chosenBtns.forEach((item) => {
            results.push(parseInt(item.clickedInd) + 1);
        });

        let payload = {
            results
        };

        RequestService.post(`api/test/${id}`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
            'Content-Type': 'application/json'
        }, JSON.stringify(payload), false)
        .then((resp) => {
            if (resp.status === 'success') {
                setIsModalOpen(true);
                setModalTxt('Test succesfully passed');
            } else {
                setIsModalOpen(true);
                if (resp.message === 'Вы уже отвечали на этот тест') {
                    setModalTxt('You have already passed this test');
                } else {
                    setModalTxt('Error occured');
                }
            }
        });
    }

    useEffect(() => {
        RequestService.get(`api/test/${id}`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
        })
        .then((resp) => {
            if (resp.status === 'success') {
                let test = resp.data.test;
                setTestName(test.name);
                setTestValues(Object.values(test.values));
                setButtonsAmount(Object.keys(test.values).length);
            }
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Add new test</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Typography color="textPrimary" variant="h2">
                        {testName}
                    </Typography>
                    <Typography style={{ marginTop: '10px', fontSize: '18px' }}>
                        Press all 13 buttons in random sequence, not thinking
                    </Typography>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '14px' }} onClick={testBtnClicked}>
                        <Grid container spacing={2}>
                            {
                                testValues.map((item, ind) => {
                                    return <Grid item xs={2} key={ind}>
                                                <Button
                                                  color="primary"
                                                  variant="contained"
                                                  style={{ width: '100%', overflow: 'hidden' }}
                                                  index={ind}
                                                  text={item.name}
                                                  disabled={btnsClicked[ind]}
                                                >
                                                    {ind+1}. {item.name}
                                                </Button>
                                            </Grid>
                                })
                            }

                        </Grid>
                    </Box>
                    <Divider style={{ margin: '20px 0' }} />
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box style={{ width: '80%', padding: '10px 20px 10px 10px' }}>
                            <Grid container spacing={2}>
                                {
                                    chosenBtns.map((item, ind) => {
                                        return <Grid item xs={2} key={ind} style={{ position: 'relative' }}>
                                                    <Alert
                                                      variant="outlined"
                                                      severity="info"
                                                      icon={false}
                                                      style={{
                                                          justifyContent: 'center',
                                                          width: '100%'
                                                      }}
                                                    >
                                                        <span>Question {parseInt(item.clickedInd) + 1}</span>
                                                    </Alert>
                                                    <div
                                                      onClick={() => removeVariant(ind, item.clickedInd)}
                                                      style={{
                                                            position: 'absolute',
                                                            top: '10px',
                                                            right: '-7px',
                                                            backgroundColor: 'white',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            boxShadow: '0 0 12px rgba(0, 0, 0, 0.1)',
                                                            cursor: 'pointer',
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        <CrossIcon />
                                                    </div>
                                                </Grid>
                                    })
                                }
                            </Grid>
                        </Box>
                        <Box style={{ width: '20%', padding: '10px' }}>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{ width: '100%' }}
                                disabled={chosenBtns.length < buttonsAmount}
                                onClick={sendTest}
                            >
                                Send
                            </Button>
                            <Typography style={{ marginTop: '14px', textAlign: 'center' }}>
                                Thank you for passing our test! Results will be sent to your email
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Modal
              open={isModalOpen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                    <Box style={{
                            backgroundColor: '#fff',
                            padding: '15px 25px',
                            borderRadius: '5px'
                        }}
                    >
                        <Typography id="modal-modal-title" color="textPrimary" variant="h3">
                            Pass result
                        </Typography>
                        <Typography id="modal-modal-description" style={{ fontSize: '21px', marginTop: '10px' }}>
                            {modalTxt}
                        </Typography>
                        <Button color="primary" variant="contained" style={{ width: '100%', marginTop: '16px' }} onClick={() => setIsModalOpen(false)}>
                            OK
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default TestPass;
