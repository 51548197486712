import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Avatar,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    CircularProgress,
    Alert,
    Input
} from '@material-ui/core';
import { useGet, usePut } from '../../API/request';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Clear } from '@material-ui/icons';

const ProfessionFormEdit = ({profId}) => {

    const getU = useGet();
    const putU = usePut();

    const [tags, setTags] = useState({});
    const [dataToSend, setDataToSend] = useState([]);

    const [values, setValues] = useState({
        name: '',
        industry: '',
        values: [],
        language: ''
    });
    console.log(values.values)

    const [errors, setErrors] = useState({
        industry: false,
        values: false
    });

    const [isShowLoader, setIsShowLoader] = useState(false);

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const [dataBeforeChange, setDataBeforeChange] = useState({});


    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        //
        // if (dataBeforeChange.values === values.values) {
        //     let validComplete = false;
        //     showAlert('error', 'Nothing changed');
        // }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        // if (nothingChanged) {
        //     showAlert('error', 'Nothing changed');
        //     return;
        // }

        if (validate()) {
            setSubmitDisabled(true);

            const data = {
                industry: values.industry,
                name: values.name,
                language: values.language,
                tags: dataToSend
            };

            setIsShowLoader(true);

            putU(`api/tests/proficiency/professions/${profId}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Talent Edited');
                    } else {
                        showAlert('error', 'Error occured');
                    }

                    setIsShowLoader(false);
                })
                .catch((err) => {
                    setIsShowLoader(false);
                    showAlert('error', 'Server error occured');

                });
        }
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const onChangeTag = (e) => {
        setNothingChanged(false);
        setTags({ ...tags, [e.target.name]: e.target.value });

        setValues({ ...values, values: Object.values({ ...tags, [e.target.name]: e.target.value }) });

        setDataToSend(Object.values({ ...tags, [e.target.name]: e.target.value }));
    };

    const deleteTag = async (index) => {
        setNothingChanged(false);

        values.values.splice(index, 1);

        let reduce = values.values.reduce((a, v, i) => ({ ...a, [i + 1]: v }), {});

        setDataToSend(Object.values(reduce));

        setTags(reduce);

    };

    const addOneMoreTalent = async () => {

        await setTags({ ...tags, [values.values.length + 1]: '' });

        await setValues({ ...values, values: Object.values({  ...tags, [values.values.length + 1]: '' }) });
    };

    useEffect(() => {
        getU(`api/tests/proficiency/professions/${profId}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    let professions = resp.data;

                    let vals = {
                        industry: professions.industry || '',
                        name: professions.name || '',
                        values: professions.tags || '',
                        language: professions.language
                    };

                    let beforeChange = {
                        industry: professions.industry,
                        name: professions.name,
                        values: professions.values
                    };

                    let reduce = professions.tags.reduce((a, v, i) => ({ ...a, [i + 1]: v }), {});
                    setTags(reduce);

                    setDataBeforeChange(beforeChange);
                    setValues(vals);
                }
            });

    }, []);

    return (
        <form>
            <Card>
                <CardHeader
                    title="Edit profession"
                />
                <Divider/>
                <CardContent sx={{ position: 'relative' }}>
                    {isShowLoader && (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#ffffff',
                            zIndex: 5
                        }}
                        >
                            <CircularProgress/>
                        </Box>
                    )}

                    <TextField
                        fullWidth
                        label="Industry"
                        margin="normal"
                        name="industry"
                        onChange={handleChange}
                        type="text"
                        value={values.industry}
                        variant="outlined"
                        error={errors.industry}
                    />
                    <TextField
                        fullWidth
                        label="Name"
                        margin="normal"
                        name="name"
                        onChange={handleChange}
                        type="text"
                        value={values.name}
                        variant="outlined"
                        error={errors.name}
                    />

                    <CardHeader
                        title="Profession"
                    />
                    <Divider/>
                    {
                        Object.values(tags)?.map((item, index) => {
                                return (
                                    <TextField
                                        key={index}
                                        fullWidth
                                        multiline
                                        label={index + 1}
                                        margin="normal"
                                        name={`${index + 1}`}
                                        onChange={onChangeTag}
                                        type="text"
                                        value={item}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end"
                                                                sx={{ cursor: 'pointer' }}
                                                                onClick={() => deleteTag(index)}
                                                >
                                                    <Clear/>
                                                </InputAdornment>
                                            )
                                        }}
                                        // error={errors.values}
                                    />

                                );
                            }
                        )
                    }
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <Button
                            // disabled={true}
                            color="primary"
                            variant="contained"
                            onClick={addOneMoreTalent}
                            disabled={submitDisabled}
                        >
                            Add one more
                        </Button>
                    </Box>

                    <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                        {alert.txt}
                    </Alert>
                </CardContent>


                <Divider/>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                    <Button
                        // disabled={true}
                        color="primary"
                        variant="contained"
                        onClick={submit}
                        // disabled={submitDisabled}
                    >
                        Save
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

ProfessionFormEdit.propTypes = {
    id: PropTypes.string.isRequired
};

export default ProfessionFormEdit;
