import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    Typography,
    CircularProgress,
    Alert,
    Input
} from '@material-ui/core';
import { useGet, usePut } from '../../API/request';

const UserFormEdit = (props) => {

    const getU = useGet();
    const putU = usePut();

    const { id } = props;
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        login: '',
        avatar: '',
        phoneNumber: '',
        password: '',
        confirm: ''
    });
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        email: false,
        login: false,
        avatar: false,
        phoneNumber: false,
        password: false,
        confirm: false
    });

    const [isValidateEmail, setIsValidateEmail] = useState(true);
    const [isShowLoader, setIsShowLoader] = useState(false);

    const [isAvaLoaded, setIsAvaLoaded] = useState(false);
    const [uploadedImgName, setUploadedImgName] = useState('');

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [role, setRole] = useState('User');
    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });
    const [dataBeforeChange, setDataBeforeChange] = useState({});

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const handleChangeEmail = (event) => {
        const emailRe = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
        setIsValidateEmail(emailRe.test(event.target.value));
        setValues({
            ...values,
            email: event.target.value
        });
    };

    const resetPass = () => {
        setValues({
            ...values,
            password: '',
            confirm: ''
        });
    };

    const avaUploaded = (event) => {
        setNothingChanged(false);

        let pathParts = event.target.value.split('\\');
        setUploadedImgName(pathParts[pathParts.length - 1]);
        setIsAvaLoaded(true);
        setValues({
            ...values,
            avatar: event.target.files[0]
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.firstName === '') {
            validComplete = false;
            formErrors.firstName = true;
        }

        if (values.lastName === '') {
            validComplete = false;
            formErrors.lastName = true;
        }

        if (!isValidateEmail || values.email === '') {
            validComplete = false;
            formErrors.email = true;
            setIsValidateEmail(false);
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Nothing changed');
            return;
        }

        if (validate()) {
            setSubmitDisabled(true);

            const payload = {
                name: values.firstName,
                surname: values.lastName,
                email: dataBeforeChange.email || '',
                role: role.toLowerCase(),
                phoneNumber: values.phoneNumber
            };

            if (values.email !== dataBeforeChange.email) {
                payload.email = values.email;
            }

            if (values.avatar !== '') {
                payload.avatar = values.avatar;
            }

            let data = new FormData();
            for (let key in payload) {
                data.append(key, payload[key]);
            }

            setIsShowLoader(true);

            putU(`api/users/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        resetPass();
                        showAlert('success', 'User edited');
                    } else {
                        showAlert('error', 'Error occured');
                    }

                    setIsShowLoader(false);
                })
                .catch((err) => {
                    setIsShowLoader(false);
                    if (err.response.status === 400) {
                        if (err.response.data.message === 'Пользователь с таким email уже существует') {
                            showAlert('error', 'User with such email already exists');
                        }
                        if (err.response.data.message === 'Пользователь с таким login уже существует') {
                            showAlert('error', 'User with such login already exists');
                        }
                    } else {
                        showAlert('error', 'Server error occured');
                    }
                });
        }
    };

    useEffect(() => {
        getU(`api/users/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    let userData = resp.data.user;
                    let vals = {
                        email: userData.email,
                        firstName: userData.name || '',
                        lastName: userData.surname || '',
                        phoneNumber: userData.phoneNumber || '',
                        avatar: '',
                        login: ''
                    };
                    let beforeChange = {
                        email: userData.email,
                        login: userData.login
                    };

                    let roleArr = userData.role.split('');
                    roleArr[0] = roleArr[0].toUpperCase();
                    let roleStr = roleArr.join('');

                    if (roleStr === 'Admin') {
                        vals.login = userData.login;
                        beforeChange.login = userData.login;
                    }

                    setDataBeforeChange(beforeChange);
                    setRole(roleStr);
                    setValues(vals);
                }
            });
    }, []);

    return (
        <form>
            <Card>
                <CardHeader
                    title="Edit user"
                />
                <Divider/>
                <CardContent sx={{ position: 'relative' }}>
                    {isShowLoader && (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#ffffff',
                            zIndex: 5
                        }}
                        >
                            <CircularProgress/>
                        </Box>
                    )}
                    <Typography color="textPrimary" variant="h4">Avatar</Typography>
                    <Box>
                        <Input accept="image/*" type="file" style={{ display: 'none' }} id="userAvatarUpload"
                               onChange={avaUploaded}/>
                        <label htmlFor="userAvatarUpload">
                            <Button variant="contained" style={{ marginTop: '7px' }} component="span">
                                Upload
                            </Button>
                        </label>
                        <Typography
                            style={{
                                display: isAvaLoaded ? 'inline' : 'none',
                                marginLeft: '8px',
                                position: 'relative',
                                top: '3.5px'
                            }}
                        >
                            {uploadedImgName}
                        </Typography>
                    </Box>
                    <TextField
                        fullWidth
                        label="First name"
                        margin="normal"
                        name="firstName"
                        onChange={handleChange}
                        type="text"
                        value={values.firstName}
                        variant="outlined"
                        error={errors.firstName}
                    />
                    <TextField
                        fullWidth
                        label="Last name"
                        margin="normal"
                        name="lastName"
                        onChange={handleChange}
                        type="text"
                        value={values.lastName}
                        variant="outlined"
                        error={errors.lastName}
                    />
                    <TextField
                        fullWidth
                        label="Email"
                        margin="normal"
                        name="email"
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                        error={!isValidateEmail}
                        helperText={!isValidateEmail && 'Type correct email'}
                    />
                    <TextField
                        fullWidth
                        label="Phone Number"
                        margin="normal"
                        name="phoneNumber"
                        onChange={handleChange}
                        type="tel"
                        value={values.phoneNumber}
                        variant="outlined"
                        error={errors.phoneNumber}
                        inputProps={{ maxLength: 15 }}
                    />
                    <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                        {alert.txt}
                    </Alert>
                </CardContent>


                <Divider/>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={submit}
                        disabled={submitDisabled}
                    >
                        Save
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

UserFormEdit.propTypes = {
    id: PropTypes.string.isRequired
};

export default UserFormEdit;
