import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import UserFormCreate from '../../components/users/UserFormCreate';

const UserAdd = () => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Create new user</title>
            </Helmet>
            <Box sx={{ pt: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <UserFormCreate />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default UserAdd;
