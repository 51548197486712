import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { apiUrl } from '../../enviroment';
import { useGet } from '../../API/request';
import moment from 'moment';
import '../../styles/Feedback/feedbakcStyles.css';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    wrapAvatar: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex'
    },
    wrapInner: {
        flexDirection: 'row',
        display: 'flex'
    },
    label: {
        width: 200,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: 10
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto'
    },
    panel: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#5664d21c'
    },
    panelInCareington: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#79b3ff1c',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    }
});

const FeedBackInfo = () => {

    const getU = useGet();
    const { id } = useParams();
    const [result, setResult] = useState({});
    const styles = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        getU(`api/feedbacks/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    setResult(resp.data);
                }
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>Feedbak info</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Card>
                        <CardContent sx={{ p: 3 }}>
                            <div className={styles.wrapAvatar}>
                                <div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                ID
                                            </div>
                                            <div className={styles.text}>
                                                {result._id || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Name
                                            </div>
                                            <div className={styles.text}>
                                                {result.name || '---'}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Email:
                                            </div>
                                            <div className={styles.text}>
                                                {result.email || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Message:
                                            </div>
                                            <div className={styles.text}>
                                                {result.message || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Date created:
                                            </div>
                                            <div className={styles.text}>
                                                {moment(result.createdAt).format('DD.MM.YYYY')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div className="image-title">
                                Images:
                            </div>
                            <div className="feedbackImages">
                                {
                                    result?.attachments?.map((item, i) =>
                                        <img key={i} src={`${apiUrl}/uploads/feedbackAttachments/${item}`} alt=""/>
                                    )

                                }
                            </div>
                        </CardContent>

                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default FeedBackInfo;
