import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import EmailTemplateListResults from '../../components/email-template/EmailTemplateListResults';
import EmailTemplatesSkelet from '../../skeletons/EmailTemplatesSkelet';
import { useConfirm } from '../../components/confirm';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import { useDelete, useGet } from '../../API/request';
import PaymentListResult from '../../components/payment/PaymentListResult';

const PaymentList = () => {

    const [paymentData, setPaymentData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const getU = useGet();

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const loadData = async () => {
        setDataLoading(true);

        getU(`api/payments?page=${page + 1}&limit=${limit}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    let data = resp.data.payments;
                    setPaymentData(data);
                    setCount(resp.data.count);
                }
                setDataLoading(false);
            });
    };

    useEffect(() => {
        loadData();
    }, [page, limit]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    return (
        <>
            <Helmet>
                <title>Payment</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        dataLoading ?
                            <EmailTemplatesSkelet />
                            :
                            <>
                                <Box sx={{ pt: 3 }}>
                                    {
                                        paymentData?.length > 0 ?
                                            <PaymentListResult paymentData={paymentData}
                                                               handleChangePage={handleChangePage}
                                                               handleChangeLimit={handleChangeLimit}
                                                               page={page}
                                                               limit={limit}
                                                               count={count}/>
                                            :
                                            <Typography color="textPrimary" variant="h2">
                                                Templates were not loaded
                                            </Typography>
                                    }
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default PaymentList;
