import React from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
} from '@material-ui/core';

const TestList = ({ testsData, testDelete }) => (
    <Card>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ width: 80 }}>
                        Id
                    </TableCell>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Type
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {testsData.map((test) => (
                    <TableRow hover key={test.id}>
                        <TableCell sx={{ width: 80 }}>
                            {test.id}
                        </TableCell>
                        <TableCell>
                            {test.name}
                        </TableCell>
                        <TableCell>
                            {test.type}
                        </TableCell>
                        <TableCell>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Link to={`/app/test/${test.id}`}>
                                    <Button color="primary" variant="contained">
                                        Info
                                    </Button>
                                </Link>
                                <Box sx={{ ml: 2 }}>
                                    <Link to={`/app/tests/edit/${test.id}`}>
                                        <Button color="primary" variant="contained">
                                            Edit
                                        </Button>
                                    </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                    <Link to={`/app/tests/pass/${test.id}`}>
                                        <Button color="primary" variant="contained">
                                            Pass
                                        </Button>
                                    </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                    <Button color="primary" variant="contained" onClick={() => testDelete(test.id)}>
                                        Delete
                                    </Button>
                                </Box>
                            </Box>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Card>
);

export default TestList;