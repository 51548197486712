import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    TableFooter,
    TablePagination,
    Card,
    Divider, Input, CardActions, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useConfirm } from '../../components/confirm';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import TestResultsSkelet from '../../skeletons/TestResultsSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { apiUrl } from '../../enviroment';
import { setAva } from '../../redux/slices/dashboard';

const ProfessionList = () => {

    const getU = useGet();
    const postU = usePost();
    const navigate = useNavigate();

    const [dataLoading, setDataLoading] = useState(true);
    const [results, setResults] = useState([]);


    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(13);
    const [count, setCount] = useState(0);

    const [xmlTable, setXmlTable] = useState('');

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });


    const loadResults = () => {
        let endpoint = `api/tests/proficiency/professions?page=${page + 1}&limit=${limit}`;

        setDataLoading(true);

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setResults(resp.data.professions);
                    setCount(resp.data.count);
                }
                setDataLoading(false);
            });
    };

    const loadXML = () => {
        if (xmlTable !== '') {

            let data = new FormData();
            data.append('spreadsheet', xmlTable);

            postU(`api/tests/proficiency/professions`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Еhe table is loaded');
                    } else {
                        showAlert('error', 'Error occured');
                    }
                });
        }
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 1400);
    };

    const XMLUpLoaded = (event) => {
        setXmlTable(event.target.files[0]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        loadResults();
    }, [page, limit]);

    useEffect(() => {
        loadXML();
    }, [xmlTable]);

    return (
        <>
            <Helmet>
                <title>Test results</title>
            </Helmet>
            <Box sx={{ pt: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>

            <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                {alert.txt}
            </Alert>

            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        mb: 3
                    }}>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 5 }}>
                            <Input accept="image/*" type="file" style={{ display: 'none' }} id="userAvatarUpload"
                                   onChange={XMLUpLoaded}/>
                            <label htmlFor="userAvatarUpload">
                                <Button variant="contained" color="primary" fullWidth component="span">
                                    Load the xml table
                                </Button>
                            </label>
                        </Box>


                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {/*<Link to="/app/professions/add">*/}
                            {/*    <Button color="primary" variant="contained">*/}
                            {/*        Add profession*/}
                            {/*    </Button>*/}
                            {/*</Link>*/}
                            <Button color="primary" variant="contained" disabled>
                                Add profession
                            </Button>
                        </Box>
                    </Box>
                    {
                        dataLoading ?
                            <TestResultsSkelet/>
                            :
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{ minWidth: 1050 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: 80 }}>
                                                        ID
                                                    </TableCell>
                                                    <TableCell>
                                                        Name
                                                    </TableCell>
                                                    <TableCell>
                                                        Industry
                                                    </TableCell>
                                                    <TableCell>
                                                        Language
                                                    </TableCell>
                                                    <TableCell>
                                                        Values
                                                    </TableCell>
                                                    <TableCell/>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {results?.map((result) => (
                                                    <TableRow hover key={result._id}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            {result._id}
                                                        </TableCell>
                                                        <TableCell sx={{ width: 200 }}>
                                                            {result.name}
                                                        </TableCell>
                                                        <TableCell sx={{ width: 300 }}>
                                                            {result.industry}
                                                        </TableCell>
                                                        <TableCell>
                                                            {result.language.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                result.tags.map((item, index) =>
                                                                    <p>
                                                                        {index + 1 + ': ' + item}
                                                                    </p>
                                                                )
                                                            }
                                                        </TableCell>

                                                        <TableCell>
                                                            <Link to={`/app/professions/edit/${result._id}`}>
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[13, 26, 39, 52, 65]}
                                                        colSpan={7}
                                                        count={count}
                                                        rowsPerPage={limit}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeLimit}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>
                            </Card>
                    }
                </Container>
            </Box>
        </>
    );
};

export default ProfessionList;
