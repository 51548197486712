import { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import UserFormEdit from '../../components/users/UserFormEdit';
import UserSetPassForm from '../../components/users/UserSetPassForm';
import TalantFormEdit from '../../components/talents/TalantFormEdit';
import TagFormEdit from '../../components/tags/TagFormEdit';
import DirectionFormEdit from '../../components/directions/DirectionFormEdit';


const DirectionsEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <Helmet>
                <title>Edit user</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false} >
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <DirectionFormEdit directId={String(id)}/>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default DirectionsEdit;
