import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../../API/request';
import { apiUrl } from '../../../enviroment';
import { useConfirm } from '../../../components/confirm';

const OrderConsultationList = () => {

    const getU = useGet();
    const deleteU = useDelete();
    const confirm = useConfirm();

    const [data, setData] = useState([]);
    const [filterEmail, setFilterEmail] = useState('');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const [isDataLoading, setIsDataLoading] = useState(true);

    const loadData = () => {
        setIsDataLoading(true);

        let endpoint = `api/consultations?page=${page + 1}&limit=${limit}`;

        if (filterEmail !== '') {
            endpoint += `&email=${filterEmail}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp.data.consultations);
                    setCount(resp.data.count);
                }
                setIsDataLoading(false);
            })
            .catch(() => {
                setData([]);
                setCount(0);
                setIsDataLoading(false);
            });
    };

    const loadDataWithArgParams = (searchParams) => {
        setIsDataLoading(true);

        let endpoint = `api/consultations?page=${page + 1}&limit=${limit}`;

        if (searchParams.email !== '') {
            endpoint += `&email=${searchParams.email}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setData(resp.data.consultations);
                    setCount(resp.data.count);
                }
                setIsDataLoading(false);
            })
            .catch(() => {
                setData([]);
                setCount(0);
                setIsDataLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterEmail = (event) => {
        setFilterEmail(event.target.value);

        if (event.target.value === '') {
            loadDataWithArgParams({
                email: '',
            });
        }
    };

    const handleFilter = () => {
        loadData();
    };

    useEffect(() => {
        loadData();
    }, [page, limit]);

    const onDelete = (id) => {

        confirm({
            title: 'Deleting consultation',
            content: 'Are you sure you want to delete the consultation?',
            onConfirm: () => {
                deleteU(`api/consultations/${id}`)
                    .then((resp) => {
                        if (resp.statuss === 'success') {
                            loadData();
                        }
                    })
                    .catch((e)=>{
                        console.log( e.response?.data?.message)
                        showAlert('error', e.response?.data?.message);
                    });
            }
        });
    };


    return (
        <>
            <Helmet>
                <title>Order Consultation</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet isWithTopButton={false}/>
                            :
                            <>
                                <Box sx={{ pt: 3 }}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{ minWidth: 700 }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    gap: '10px',
                                                    mx: 2,
                                                    mb: 1
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Email "
                                                        margin="normal"
                                                        name="filterEmail"
                                                        onChange={handleFilterEmail}
                                                        type="text"
                                                        value={filterEmail}
                                                        variant="outlined"
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={handleFilter}
                                                        sx={{ mt: 2, mb: 1 }}
                                                    >
                                                        Filter
                                                    </Button>
                                                </Box>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ width: 80 }}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Email
                                                            </TableCell>
                                                            <TableCell>
                                                                Статус
                                                            </TableCell>
                                                            <TableCell/>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.map((item) => (
                                                            <TableRow hover key={item.id}>
                                                                <TableCell sx={{ width: 80 }}>
                                                                    {item._id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.user.email}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.status}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{ display: 'flex' }}>
                                                                        <Box sx={{ ml: 2 }}>
                                                                            <Link to={`/app/consultation/${item._id}`}>
                                                                                <Button color="primary"
                                                                                        variant="contained">
                                                                                    Edit
                                                                                </Button>
                                                                            </Link>
                                                                        </Box>
                                                                        <Box>
                                                                            <Button
                                                                                sx={{ ml: 1 }}
                                                                                color="error"
                                                                                variant="contained"
                                                                                onClick={() => onDelete(item?._id)}>
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default OrderConsultationList;
