import { useDispatch, useSelector } from 'react-redux';
import { getTransport, configureTransport } from './transport';
import {logout, setTokens} from "../redux/slices/auth";
import TokenStorage from "./TokenStorage";
import { useState } from 'react';

const generateHook = (callback) => () => {
    const dispatch = useDispatch();

    return callback(async (e) => {
        if (e?.response?.status !== 401 ) throw e;
        try{
            const res = await post('api/auth/refresh', {}, {headers: {
                    'Authorization': `Bearer ${TokenStorage.getRefreshToken()}`
                }});

            configureTransport(res.data.access)
            dispatch(setTokens({
                accessToken: res.data.access,
                refreshToken: res.data.refresh,
                tokenExpire: res.data.tokenExpire,
                // user: res.data.user
            }));

            await TokenStorage.setAccessToken(res.data.access)
            await TokenStorage.setRefreshToken(res.data.refresh)
            await TokenStorage.setTokenExpire(res.data.expiresIn)
            // await TokenStorage.setUser(res.data.expiresIn)
            await TokenStorage.setTokenReceived(Date.now() / 1000);

            delete e.response.config?.headers?.Authorization;
            const buf = (await getTransport()(e.response.config))?.data;
            return buf
        }
        catch (e) {
            logout(dispatch)
            throw e;
        }
    });
};

export const usePost = generateHook((middleware) => (
    (path, payload, config) => getTransport()
        .post(`/${path}`, payload, config)
        .then((response) => response.data)
        .catch(middleware)
));

export const useGet = generateHook((middleware) => (
    (path, token, config) => getTransport(token)
        .get(`/${path}`, config)
        .then((response) => response.data)
        .catch(middleware)
));

export const usePut = generateHook((middleware) => (
    (path, payload, config) => getTransport()
        .put(`/${path}`, payload, config)
        .then((response) => response.data)
        .catch(middleware)
));

export const useDelete = generateHook((middleware) => (
    (path, payload, config) => getTransport()
        .delete(`/${path}`, payload, config)
        .then((response) => response.data)
        .catch(middleware)
));

export const get = (path, config) => getTransport()
    .get(`/${path}`, config).then((response) => response.data);

export const post = async (path, payload, config) => getTransport()
    .post(`/${path}`, payload, config)
    .then((response) => response.data);

export const put = (path, payload = {}) => getTransport()
    .put(`/${path}`, payload)
    .then((response) => response.data);

export const deleteRequest = (path, payload = {}) => getTransport()
    .delete(`/${path}`, payload)
    .then((response) => response.data);

export const patch = (path, payload = {}) => getTransport()
    .patch(`/${path}`, payload)
    .then((response) => response.data);

export const httpDelete = (path, config) => getTransport()
    .delete(`/${path}`, config)
    .then((response) => response.data);
